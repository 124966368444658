import React, {useEffect, useState} from "react";
import ArticleModel from "../models/ArticleModel";
import {Box, Button, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField} from "@mui/material";
import articleService from "../services/ArticleService";
import {plainToInstance} from "class-transformer";
import {useNavigate} from "react-router-dom";
import articleCategoryService from "../services/ArticleCategoryService";
import ArticleCategoryModel from "../models/ArticleCategoryModel";
import moment from "moment/moment";

type MyTableProps = {

    articles: Array<ArticleModel>
}

function MyTable(props: MyTableProps) {

    const navigate = useNavigate()
    const [articles, setArticles] = useState<Array<ArticleModel>>([])
    const [desc, setDesc] = useState<boolean>()

    useEffect(() => {
        setArticles(props.articles)
    }, [articles, props.articles]);

    return <TableContainer sx={{overflowX: 'initial'}}>
        <Table sx={{minWidth: 650}} size="small" aria-label="table" stickyHeader>
            <TableHead sx={{
                "& .MuiTableCell-head": {
                    fontWeight: 700
                }
            }}>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Published?</TableCell>
                    <TableCell align="left" width={"300"}>Title</TableCell>
                    <TableCell align="left">Categories</TableCell>
                    <TableCell align="right" onClick={() => {
                        setDesc(!desc)
                        props.articles.sort((a, b) =>
                            (desc ?
                                moment(b.createTime).unix() - moment(a.createTime).unix()
                                :
                                moment(a.createTime).unix() - moment(b.createTime).unix()
                            )
                        )
                        setArticles([])
                    }} style={{
                        cursor: 'pointer'
                    }}>
                        <TableSortLabel
                            active={desc !== undefined}
                            direction={desc ? 'desc' : 'asc'}
                            onClick={() => {}}
                        >
                            Creator | Date
                        </TableSortLabel>
                    </TableCell>
                </TableRow>
            </TableHead>
                <TableBody>
                    {articles.map((row, index) => (
                    <TableRow
                        key={'tb' + index}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        hover
                        onClick={() => {
                            navigate('/article-editor/' + row.id)
                        }}
                    >
                        <TableCell component="th" scope="row">{row.id}</TableCell>
                        <TableCell align="left">{row.published ? "true" : "false"}</TableCell>
                        <TableCell align="left" width={"300"}>{row.title.substring(0, 40)}</TableCell>
                        <TableCell align="left">{row.categories}</TableCell>
                        <TableCell align="right">{row.creator} | {row.createTime.toString()}</TableCell>
                    </TableRow>
                    ))}
                </TableBody>
        </Table>
    </TableContainer>
}

export function ArticleListPage() {

    const [articles, setArticles] = useState(new Array<ArticleModel>())
    const [articleCategories, setArticleCategories] = useState<Array<string>>([])

    useEffect(() => {
        articleCategoryService.get((status, resp) => {
            const temp: Array<string> = resp.data.map((value: string) => {
                return plainToInstance(ArticleCategoryModel, value).name
            })
            setArticleCategories(temp)
        })
        loadArticles("article")
    }, []);

    const loadArticles = (filter: string) => {
        articleService.getAll((resp) => {
            const temp: Array<ArticleModel> = resp.data.map((value: string) => {
                return plainToInstance(ArticleModel, value)
            })
            setArticles(temp.filter((item, index) => {
                return item.categories.includes(filter)
            }))
        })
    }

    return <>
        <Box sx={{display: 'flex', justifyContent: "space-evenly"}}>
            <TextField select sx={{m: 2}} size={"small"} label={"Filter by category"} style={{minWidth: "200px", maxWidth: "300px", margin: "5px"}}
                       onChange={(event) => {
                           loadArticles(event.target.value)
                       }}>
                {articleCategories.map((value, index) => (
                    <MenuItem key={index} value={value}>{value}</MenuItem>
                ))}
            </TextField>
            <Button variant={"contained"} sx={{m: "5px"}} onClick={() => {
                articleService.createNewArticle((status, resp) => {
                    if (status === 200) {
                        loadArticles("article")
                    }
                })
            }}>Create new</Button>
        </Box>
        <MyTable articles={articles}/>
    </>
}