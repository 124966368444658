import {
    Box,
    Button,
    FormGroup,
    IconButton,
    InputAdornment,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {instanceToInstance, plainToInstance} from "class-transformer";
import ArticleCategoryModel from "../models/ArticleCategoryModel";
import articleCategoryService from "../services/ArticleCategoryService";
import {Field, Form, Formik} from "formik";
import {GVSelect, GVTextField} from "@gecsevar.hu/gecsevar-formik-fields";
import {object, string} from "yup";
import {ClearIcon} from "@mui/x-date-pickers";
import ArticleModel from "../models/ArticleModel";
import articleService from "../services/ArticleService";
import {motion} from "framer-motion";
import {AddOutlined} from "@mui/icons-material";

type MyTableProps = {
    published: Array<ArticleCategoryModel>
    editFunction: (ac: ArticleCategoryModel) => void
}

function MyTable(props: MyTableProps) {

    return <Paper sx={{width: '100%', overflow: 'hidden'}}>
        <TableContainer sx={{overflowX: 'initial'}}>
            <Table sx={{minWidth: 650}} size="small" aria-label="table" stickyHeader>
                <TableHead sx={{
                    "& .MuiTableCell-head": {
                        fontWeight: 700
                    }
                }}>
                    <TableRow>
                        <TableCell align="left">ID</TableCell>
                        <TableCell align="right">Name</TableCell>
                        <TableCell align="right">Active</TableCell>
                        <TableCell align="right">Description article</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.published.map((row, index) => (
                        <TableRow
                            key={'tb' + index}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            hover
                            onClick={() => {
                                props.editFunction(row)
                            }}
                        >
                            <TableCell align="left">{row.id}</TableCell>
                            <TableCell align="right">{row.name}</TableCell>
                            <TableCell align="right">{row.active ? "true":"false"}</TableCell>
                            <TableCell align="right">{row.articleId} | {row.articleTitle}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Paper>
}

const formValidationSchema = object({
    articleCategory: object({
        name: string().required("Must be something!"),
    })
});

export function ArticleCategoryListPage() {

    const [categories, setCategories] = useState(new Array<ArticleCategoryModel>())
    const [editedCategory, setEditedCategory] = useState(new ArticleCategoryModel())
    const [articles, setArticles] = useState(new Array<ArticleModel>())

    useEffect(() => {
        loadCategories()
        articleService.getAll((resp) => {
            const temp: Array<ArticleModel> = resp.data.map((value: string) => {
                return plainToInstance(ArticleModel, value)

            })
            setArticles(temp.filter((item, index) => {
                return item.published === true
            }))
        })
    }, []);

    const loadCategories = () => {
        articleCategoryService.get((status, resp) => {
            const temp: Array<ArticleCategoryModel> = resp.data.map((value: string) => {
                return instanceToInstance(value)
            })
            setCategories(temp)
        })
    }

    return <Box>
        <MyTable published={categories} editFunction={(ac) => {
            setEditedCategory(ac)
        }}/>
        <Box sx={{display: 'flex', minHeight:'48px', backgroundColor: '#101F33'}} marginTop={1}></Box>
        <Formik
            initialValues={{
                articleCategory: editedCategory,
            }}
            onSubmit={(values, actions) => {
                actions.setSubmitting(true)
                if (values.articleCategory.id === 0) {
                    articleCategoryService.insert(JSON.stringify(values.articleCategory), (status, resp) => {
                        if (status === 200) {
                            actions.resetForm()
                            setEditedCategory(new ArticleCategoryModel())
                            loadCategories()
                        } else {
                            if (typeof resp.data === "string") {
                            }
                        }
                        actions.setSubmitting(false)
                    })
                } else {
                    articleCategoryService.update(JSON.stringify(values.articleCategory), (status, resp) => {
                        if (status === 200) {
                            actions.resetForm()
                            setEditedCategory(new ArticleCategoryModel())
                            loadCategories()
                        } else {
                            if (typeof resp.data === "string") {
                            }
                        }
                        actions.setSubmitting(false)
                    })
                }
            }}
            validationSchema={formValidationSchema}
            validateOnChange={true}    // if true, gets fucking slow ;)
            validateOnBlur={false}
            enableReinitialize={true}
        >
            {({values, handleChange, setFieldValue}) => (
                <Form>
                    <Box>
                        <FormGroup>
                            <Box sx={{display: {sm: 'block', md: 'flex'}}} marginTop={1}>
                                <Field key={2} name={`articleCategory.id`} label={"ID"} disabled={true}
                                       component={GVTextField}/>
                                <Field key={3} name={`articleCategory.name`} label={"Name"}
                                       component={GVTextField}/>
                                <Field key={4} name={`articleCategory.active`} label={"Active"} disabled={true}
                                       component={GVTextField}/>
                                <Field key={5} name={`articleCategory.articleId`} label={"Article"}
                                       InputProps={{
                                           startAdornment: (
                                               <InputAdornment position="start">
                                                   <IconButton onClick={() => {
                                                       setFieldValue(`articleCategory.articleId`, null)
                                                       setFieldValue(`articleCategory.articleTitle`, "")
                                                   }}>
                                                       <ClearIcon />
                                                   </IconButton>
                                               </InputAdornment>
                                           ),
                                       }}
                                       component={GVSelect}
                                >
                                    {articles.map((item, it) => (
                                        <MenuItem key={it} value={item.id} onClick={() => {
                                            setFieldValue(`articleCategory.articleTitle`, item.path)
                                        }}>{item.id} | {item.title}</MenuItem>
                                    ))}
                                </Field>
                            </Box>
                            <Box sx={{display: 'flex'}} marginTop={1}>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between'}} margin={4}>
                                <Button component={motion.button}
                                        initial={{
                                            rotate:"0deg"
                                        }}
                                        animate={{
                                            rotate:"360deg"
                                        }}
                                        transition={{
                                            duration:"1",
                                            //type:"spring"
                                            ease: "easeInOut"
                                        }}
                                        startIcon={
                                            <AddOutlined
                                                component={motion.svg}
                                                animate={{ rotate: 45 }}
                                                transition={{ duration: 0.3 }}
                                            />
                                        }
                                        color={"secondary"} variant={"outlined"} onClick={() => {
                                    setEditedCategory(new ArticleCategoryModel())
                                }}>
                                    Reset category
                                    https://www.youtube.com/watch?v=hjbxaYTMhy0
                                </Button>
                                <Button color={"primary"} variant={"contained"} type={"submit"}>{values.articleCategory.id === 0 ? "Save":"Update"}</Button>
                            </Box>
                        </FormGroup>
                    </Box>
                </Form>
            )}
        </Formik>
    </Box>
}