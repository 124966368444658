import {
    Box,
    Dialog,
    DialogTitle,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    MenuItem,
    Tab,
    Tabs,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import InfoIcon from '@mui/icons-material/Info';
import fileManagerService from "../services/FileManagerService";
import FileManagerModel from "../models/FileManagerModel";
import {HOST_URL} from "../services/HttpService";
import articleService from "../services/ArticleService";
import ArticleModel from "../models/ArticleModel";
import {instanceToInstance} from "class-transformer";
import FileManagerContainerModel from "../models/FileManagerContainerModel";

export interface SimpleDialogProps {
    open: boolean
    selectedValue: string
    metaType: string
    onClose: (value: string, meta: {}) => void
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export function FileSelectorDialog(props: SimpleDialogProps) {

    const { onClose, selectedValue, open } = props;
    const [loaded, setLoaded] = useState(true)
    const [files, setFiles] = useState(new Array<FileManagerModel>())
    const [value, setValue] = React.useState(0)
    const [articles, setArticles] = useState(new Array<ArticleModel>())
    const [container, setContainers] = useState(new Array<FileManagerContainerModel>())
    const [filterByPathId, setFilterByPathId] = useState(0)

    useEffect(() => {
        setFilterByPathId(0)
        fileManagerService.getContainers((status, resp) => {
            const temp: Array<FileManagerContainerModel> = resp.data.map((value: string) => {
                return instanceToInstance(value)
            })
            setContainers(temp)
        })
        fileManagerService.get(props.metaType, (resp) => {
            const temp = resp.data
            setFiles(temp)
        })
        articleService.getAll((resp) => {
            const temp: Array<ArticleModel> = resp.data.map((value: string) => {
                return instanceToInstance(value)
            })
            setArticles(temp)
        })
    }, [props.metaType, props.open]);

    const handleClose = () => {
        onClose(selectedValue, {});
    }

    const handleListItemClick = (value: string, meta: {}) => {
        onClose(value, meta);
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }

    return (
        <Dialog key={"myDialog"} onClose={handleClose} open={open} sx={{width:'content', height: 'content'}}>
            <DialogTitle>Select {props.metaType === "file" ? "document":"image"} to insert</DialogTitle>
            {loaded ? <>
                    {props.metaType === "file" ?
                        <>
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Remote documents" {...a11yProps(0)} />
                                    <Tab label="Articles" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <div role="tabpanel" hidden={value !== 0} id={`simple-tabpanel-0`} aria-labelledby={`simple-tab-0`}>
                                <List>
                                    {files.map((value, index) => (
                                        (value.path.startsWith("/files/documents/") ?
                                        <ListItem key={index} disablePadding>
                                            <ListItemButton onClick={() => {
                                                handleListItemClick(value.path + value.name, {text: value.name})
                                            }}>
                                                <ListItemText primary={value.path + value.name}/>
                                            </ListItemButton>
                                        </ListItem>
                                                :
                                                <></>
                                        )
                                    ))
                                    }
                                </List>
                            </div>
                            <div role="tabpanel" hidden={value !== 1} id={`simple-tabpanel-1`} aria-labelledby={`simple-tab-1`}>
                                <List>
                                    {articles.map((value, index) => (
                                        <ListItem key={index} disablePadding>
                                            <ListItemButton onClick={() => {
                                                handleListItemClick("/content/" + value.id, {text: value.title})
                                            }}>
                                                <ListItemText primary={value.title}/>
                                            </ListItemButton>
                                        </ListItem>
                                    ))
                                    }
                                </List>
                            </div>
                        </>
                        :
                        <>
                            <TextField select onChange={({ target: { name, value } }) => {
                                setFilterByPathId(Number(value))
                            }}>
                                {container.map((value, index) => (
                                        <MenuItem key={index} value={value.id}>{value.basePath}{value.path}</MenuItem>
                                    ))
                                }
                            </TextField>
                        <ImageList sx={{width: 'inherit', height: 'inherit'}} cols={2} rowHeight={164}>
                            {files.map((item, index) => (
                                (filterByPathId === 0 || item.pathId === filterByPathId ?
                                <ImageListItem key={index}>
                                    <img
                                        srcSet={HOST_URL + ` ${item.path}${item.name}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                        src={HOST_URL + `${item.path}${item.name}?w=164&h=164&fit=crop&auto=format`}
                                        alt={item.name}
                                        loading="lazy"
                                        onClick={() => {
                                            handleListItemClick(item.path + item.name, {alt: item.name})
                                        }}
                                    />
                                    <ImageListItemBar
                                        title={item.name}
                                        subtitle={item.meta}
                                        actionIcon={
                                            <IconButton
                                                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                                aria-label={`info about ${item}`}
                                            >
                                                <InfoIcon />
                                            </IconButton>
                                        }
                                    />
                                </ImageListItem>
                                        :
                                        <></>
                                ))
                            )}
                        </ImageList>
                        </>
                    }
                </>
                :
                <Box>Loading...</Box>
            }
        </Dialog>
    )
}
