import {createTheme} from '@mui/material/styles';
import * as locales from "@mui/material/locale";
import * as dateLocales from '@mui/x-date-pickers/locales'

// https://zenoo.github.io/mui-theme-creator
export const myDarkTheme = createTheme(
    {
        palette: {
            mode: 'dark',
            primary: {
                main: '#5893df',
            },
            secondary: {
                main: '#2ec5d3',
            },
            background: {
                default: '#192231',
                paper: '#24344d',
            },
        },
    },
    locales['huHU'],
    dateLocales['huHU'],
)

export const myLightTheme = createTheme( {

    palette: {
        mode: 'light',
    },
})