class FileManagerModel {
    constructor(file : File | null = null) {
        this._file = file
        this.name = file == null ? "":file.name
        this.meta = "size:"+file?.size+";type:"+file?.type+";"
    }

    toJSON(): string {
        const { _file: _, ...props } = this;
        return JSON.stringify(props);
    }

    isImage() {
        return this.type === "image"
    }

    id : number = 0
    pathId : number = 0
    path : string = ""
    name : string = ""
    type : string = ""
    meta : string = ""
    _file: File | null = null
    validFrom : Date | null = null
    validTo : Date | null = null
    usages : number = 0
    usageArticleIds : string = ""
}

export default FileManagerModel

export function getPathsFor(files: Array<FileManagerModel>) : Set<string> {

    let res = new Set<string>()
    files.forEach((value, index) => {
        res.add(value.path)
    })

    return res
}

export function getFilesFor(path: string, files: Array<FileManagerModel>) {

    return files.filter((value) => {
        return value.path === path
    })
}
