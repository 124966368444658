import React, {useEffect, useState} from "react";
import {plainToInstance} from "class-transformer";
import {AxiosResponse} from "axios";
import menuService from "../services/MenuService";
import {HeaderButton, NavigationItem} from "../models/HeaderMenuModel";
import {useNavigate} from "react-router-dom";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";

type MyHeaderButtonsProps = {
    headerButton: Array<HeaderButton>
}

function MyHeaderButtons(props: MyHeaderButtonsProps) {

    const navigate = useNavigate()

    return <Paper sx={{width: '100%', overflow: 'hidden'}}>
        <Typography variant={"h5"} fontStyle={"normal"} margin={0} align={"center"} width={"100%"} marginBottom={2}>
            Main header buttons
        </Typography>
        <TableContainer sx={{maxHeight: 440}}>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell align="right">Title</TableCell>
                        <TableCell align="right">Route</TableCell>
                        <TableCell align="right">Element</TableCell>
                        <TableCell align="right">Valid from</TableCell>
                        <TableCell align="right">Valid to</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.headerButton.map((row, index) => (
        <TableRow
            key={index}
            sx={{'&:last-child td, &:last-child th': {border: 0}}}
        >
            <TableCell align="right">{index}</TableCell>
            <TableCell align="right">{row.title}</TableCell>
            <TableCell align="right">{row.route}</TableCell>
            <TableCell align="right">{row.element}</TableCell>
            <TableCell align="right">{row.valid_from.toString()}</TableCell>
            <TableCell align="right">{row.valid_to?.toString()}</TableCell>
        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Paper>
}

type MyTableProps = {
    headerMenu: Array<NavigationItem>
}

function MyTable(props: MyTableProps) {

    const navigate = useNavigate()

    return <Paper sx={{width: '100%', overflow: 'hidden'}}>
        <TableContainer sx={{overflowX: 'initial'}}>
            <Table sx={{minWidth: 650}} size="small" aria-label="table" stickyHeader>
                <TableHead sx={{
                    "& .MuiTableCell-head": {
                        fontWeight: 700
                    }
                }}>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell align="right">label</TableCell>
                        <TableCell align="left">level</TableCell>
                        <TableCell align="right">article Id</TableCell>
                        <TableCell align="right">article Category</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.headerMenu.map((row) => (
                        <TableRow key={row.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell align="right">{row.id}</TableCell>
                            <TableCell align="left">{row.label}</TableCell>
                            <TableCell align="left">{row.level}</TableCell>
                            <TableCell align="right">{row.articleTitle}</TableCell>
                            <TableCell align="right">{row.articleCategoryName}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Paper>
}

export function MenuListPage() {

    const [menuMap, setMenuMap] = useState<Array<NavigationItem>>([])

    useEffect(() => {
        menuService.get((status, resp : AxiosResponse) => {
            if (status === 200) {
                const temp: Array<NavigationItem> = resp.data.map((value: string) => {
                    return plainToInstance(NavigationItem, value)
                })
                setMenuMap(temp)
            }
        })
    }, []);

    return <>
        <MyTable headerMenu={menuMap}/>
        </>
}

/*
<MyHeaderButtons headerButton={menuMap.header} />
 */