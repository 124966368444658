import React, {useEffect, useState} from "react";
import {Alert, Box, Button, Card, CardContent, FormGroup, IconButton, InputAdornment, MenuItem, Snackbar} from "@mui/material";
import {NavigationItem} from "../models/HeaderMenuModel";
import menuService from "../services/MenuService";
import {AxiosResponse} from "axios";
import {instanceToInstance, plainToInstance} from "class-transformer";
import {DragDropContext, Draggable, DropResult} from "react-beautiful-dnd";
import {getItemStyle, getListStyle, StrictModeDroppable} from "./MyDragAndDrop";
import {Field, FieldArray, Form, Formik} from "formik";
import {GVSelect, GVTextField} from "@gecsevar.hu/gecsevar-formik-fields";
import ArticleCategoryModel from "../models/ArticleCategoryModel";
import articleCategoryService from "../services/ArticleCategoryService";
import ArticleModel from "../models/ArticleModel";
import articleService from "../services/ArticleService";
import {ClearIcon} from "@mui/x-date-pickers";
import {array, number, object, string} from "yup";
import {WysiwygMenu} from "./WysiwygMenu";

const formValidationSchema = object({
    menuMap: array().of(object({
        label: string().required('Must not be empty!'),
        level: string(),
        articleId: number().when("level", {
            is: "collapse",
            then: (schema) => schema.nullable().optional(),
            otherwise: (schema) => number().when("articleCategoryId", {
                is: null,
                then: (schema) => schema.required("Article OR Article List required!"),
                otherwise: (schema) => schema.nullable().optional(),
            })
        }),
        articleCategoryId: number().when("level", {
            is: "collapse",
            then: (schema) => schema.nullable().optional(),
            otherwise: (schema) => number().when("articleId", {
                is: null,
                then: (schema) => schema.required("Article OR Article List required!"),
                otherwise: (schema) => schema.nullable().optional(),
            })
        }),
    }))
})

type MenuEditorPageProps = {}

export function MenuEditorPage(_: MenuEditorPageProps) {

    const [loaded, setLoaded] = useState(false)
    const [menuMap, setMenuMap] = useState(new Array<NavigationItem>())
    const [categories, setCategories] = useState(new Array<ArticleCategoryModel>())
    const [articles, setArticles] = useState(new Array<ArticleModel>())
    const [open, setOpen] = React.useState(false);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    }

    const onDragStart = () => {

    }
    const onDragEnd = (menu: Array<NavigationItem>, result: DropResult): void => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        let temp = menu
        const [removed] = temp.splice(result.source.index, 1)
        temp.splice(result.destination.index, 0, removed)
        temp.forEach((element, index) => {
            temp[index].id = String(index)
        })
        setMenuMap(temp)
    }

    useEffect(() => {
        menuService.get((status, resp: AxiosResponse) => {
            if (status === 200) {
                const temp: Array<NavigationItem> = resp.data.map((value: string) => {
                    return plainToInstance(NavigationItem, value)
                })
                setMenuMap(temp)
                setLoaded(true)
            }
        })
        articleCategoryService.get((status, resp) => {
            const temp: Array<ArticleCategoryModel> = resp.data.map((value: string) => {
                return instanceToInstance(value)
            })
            setCategories(temp)
        })
        articleService.getAll((resp) => {
            const temp: Array<ArticleModel> = resp.data.map((value: string) => {
                return plainToInstance(ArticleModel, value)

            })
            setArticles(temp.filter((item, index) => {
                return item.published === true
            }))
        })

    }, []);

    return <>
        <Box width={"950px"}>
            <Formik
                initialValues={{
                    menuMap: menuMap,
                }}
                onSubmit={(values, actions) => {
                    actions.setSubmitting(true)
                    menuService.update(JSON.stringify(values.menuMap), (status, resp) => {
                        if (status === 200) {
                            setOpen(true)
                        } else {
                            if (typeof resp.data === "string") {
                                setOpen(true)
                            }
                        }
                        actions.setSubmitting(false);
                    })
                }}
                err
                validationSchema={formValidationSchema}
                validateOnChange={true}    // if true, gets fucking slow ;)
                validateOnBlur={true}
                enableReinitialize={true}
            >
                {({values, setValues, setFieldValue}) => (
                    <Form>
                        <FormGroup>
                            <Snackbar
                                anchorOrigin={{vertical: "top", horizontal: "center"}}
                                autoHideDuration={6000}
                                open={open}
                                onClose={handleClose}
                                key={"vertical:top"}
                            ><Alert onClose={handleClose} sx={{width: '100%'}}>
                                Save success!
                            </Alert>
                            </Snackbar>
                            <WysiwygMenu menuMap={values.menuMap}/>
                            <Box sx={{display: 'flex', justifyContent: 'space-between'}} margin={1}>
                            <Button variant={"contained"} sx={{m: 2}} type={"submit"}>
                                Save menu
                            </Button>
                                <FieldArray name={"menuMap"}>
                                    {({insert, remove, push}: any) => (
                                        <Button variant={"outlined"} sx={{m: 2}} onClick={() => {
                                            let newItem = {
                                                id: "1",
                                                label: 'New menu item',
                                                level: 'menu',
                                                articleId: null,
                                                articleTitle: "",
                                                articleCategoryId: null,
                                                articleCategoryName: ""
                                            }
                                            newItem.id = "1"
                                            values.menuMap.forEach((item) => {
                                                if (Number(item.id) >= Number(newItem.id)) {
                                                    newItem.id = String(Number(item.id) + 10)
                                                }
                                            })
                                            push(newItem)
                                        }}>
                                            Add menu item
                                        </Button>
                                    ) }
                                </FieldArray>

                            </Box>
                            <DragDropContext onDragStart={onDragStart} onDragEnd={(result) => {
                                onDragEnd(values.menuMap, result)
                            }}>
                                <StrictModeDroppable droppableId="droppable">
                                    {(provided, snapshot): JSX.Element => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                            {values.menuMap.map((item, index) => (
                                                <Draggable key={String(item.id)} draggableId={String(item.id)} index={index}>
                                                    {(provided, snapshot): JSX.Element => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                        >
                                                            <Card sx={{minWidth: 200, m: 0}}>
                                                                <CardContent>
                                                                    <Box sx={{display: 'flex'}}>
                                                                        <Field name={`menuMap[${index}].id`} label={"ID"} sx={{width: '50px'}}
                                                                               component={GVTextField}
                                                                               disabled={true}/>
                                                                        <Field name={`menuMap[${index}].label`} label={"Label"} sx={{width: '100px'}}
                                                                               component={GVTextField}/>
                                                                        <Field name={`menuMap[${index}].level`} label={"Level"} component={GVSelect}>
                                                                            <MenuItem key={index} value={"menu"}>menu</MenuItem>
                                                                            <MenuItem key={index} value={"submenu"}>submenu</MenuItem>
                                                                            <MenuItem key={index} value={"collapse"}>collapse</MenuItem>
                                                                        </Field>
                                                                        <Field name={`menuMap[${index}].articleId`} label={"Article"}
                                                                               InputProps={{
                                                                                   startAdornment: (
                                                                                       <InputAdornment position="start">
                                                                                           <IconButton onClick={() => {
                                                                                               setFieldValue(`menuMap[${index}].articleId`, null)
                                                                                               setFieldValue(`menuMap[${index}].articlePath`, "")
                                                                                           }}>
                                                                                           <ClearIcon />
                                                                                           </IconButton>
                                                                                       </InputAdornment>
                                                                                   ),
                                                                               }}
                                                                               component={GVSelect}
                                                                        >
                                                                            {articles.map((item, it) => (
                                                                                <MenuItem key={it} value={item.id} onClick={() => {
                                                                                    setFieldValue(`menuMap[${index}].articlePath`, item.path)
                                                                                }}>{item.id} | {item.title} | {item.path}</MenuItem>
                                                                            ))}
                                                                        </Field>
                                                                        <Field name={`menuMap[${index}].articleCategoryId`} label={"Article list"}
                                                                               InputProps={{
                                                                                   startAdornment: (
                                                                                       <InputAdornment position="start">
                                                                                           <IconButton onClick={() => {
                                                                                               setFieldValue(`menuMap[${index}].articleCategoryId`, null)
                                                                                           }}>
                                                                                               <ClearIcon />
                                                                                           </IconButton>
                                                                                       </InputAdornment>
                                                                                   ),
                                                                               }}
                                                                               component={GVSelect}
                                                                        >
                                                                            {categories.map((item, it) => (
                                                                                <MenuItem key={it} value={item.id}>{item.id} | {item.name}</MenuItem>
                                                                            ))}
                                                                        </Field>
                                                                        <Button onClick={() => {
                                                                            const temp = values.menuMap.filter((item) => {
                                                                                return item.id !== menuMap[index].id
                                                                            })
                                                                            setMenuMap(temp)
                                                                        }}>
                                                                            Remove
                                                                        </Button>
                                                                    </Box>
                                                                </CardContent>
                                                            </Card>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </StrictModeDroppable>
                            </DragDropContext>
                        </FormGroup>
                    </Form>
                )}
            </Formik>
        </Box>
    </>
}
