import {AppBar, Box, Button, Container, CssBaseline, Drawer, PaletteMode, ThemeProvider, Typography} from "@mui/material";
import {ContentContainer} from "./ContentContainer";
import React, {useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {NavigationMenu} from "./NavigationMenu";
import {myDarkTheme, myLightTheme} from "./MyTheme";
import oauthService, {authenticator} from "../services/OauthService";
import {LoginForm} from "./LoginForm";
import {AnimatePresence, motion} from "framer-motion";

const ColorModeContext = React.createContext({
    toggleColorMode: () => {
    }
})

const drawerWidth = 240;

export function MainPage() {

    const navigate = useNavigate()
    const [articleId, setArticleId] = useState("0")
    const [mode, setMode] = useState<PaletteMode>('dark')
    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode: PaletteMode) =>
                    prevMode === 'light' ? 'dark' : 'light',
                )
            },
        }),
        [],
    )
    const theme = React.useMemo(
        () =>
            (mode === 'dark') ? myDarkTheme
                :
                myLightTheme,
        [mode],
    )

    if (!oauthService.isLoggedIn()) {
        if (authenticator === "TODO php") {
            return <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <Container>
                        <AnimatePresence>
                        <motion.div
                            initial={{
                                rotate:"0deg"
                            }}
                            animate={{
                                rotate:"180deg"
                            }}
                            exit={{
                                // on presence works when is gets invisible
                                rotate:"0deg"
                            }}
                            transition={{
                                duration:"1",
                                //type:"spring"
                                ease: "easeInOut"
                            }}
                        >
                            <Typography>csirkelab kapitany</Typography>
                        </motion.div>
                        </AnimatePresence>
                    <Box sx={{display: 'flex'}}>
                        <CssBaseline/>
                        <LoginForm />
                    </Box>
                    </Container>
                </ThemeProvider>
            </ColorModeContext.Provider>
        } else {
            return <Box>
                <Button onClick={() => oauthService.login()} color={"primary"} variant="contained">Login</Button>
            </Box>
        }
    } else {
        return <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <Box sx={{display: 'flex'}}>
                    <CssBaseline/>
                    <AppBar position="fixed"
                            sx={{width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`}}>

                    </AppBar>
                    <Drawer variant="permanent" sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}>
                        <NavigationMenu/>
                    </Drawer>
                    <ContentContainer/>
                </Box>
            </ThemeProvider>
        </ColorModeContext.Provider>
    }
}