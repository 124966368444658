import {Box, Button, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import React, {useEffect, useState} from "react";
import {instanceToInstance} from "class-transformer";
import scheiberPayrollService from "../../services/scheiber.hu/ScheiberPayrollService";
import {Field, FieldArray, Form, Formik} from "formik";
import {GVSelect, GVTextField} from "@gecsevar.hu/gecsevar-formik-fields";
import FileManagerModel from "../../models/FileManagerModel";
import {array, number, object, string} from "yup";

const formValidationSchema = object({
    year: string().required("Kell évet választani"),
    month: string().required("Kell hónapot választani"),
});

type MyTableProps = {

    myFiles: Array<string>
}

function MyTable(props: MyTableProps) {

    return <TableContainer sx={{overflowX: 'initial'}}>
        <Table sx={{minWidth: 650}} size="small" aria-label="table" stickyHeader>
            <TableHead sx={{
                "& .MuiTableCell-head": {
                    fontWeight: 700
                }
            }}>
                <TableRow>
                    <TableCell>File</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.myFiles.map((row, index) => (
                    <TableRow key={'tb' + index} sx={{'&:last-child td, &:last-child th': {border: 0}}} hover >
                        <TableCell component="th" scope="row">{row}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
}

export function PayrollForm() {

    const [files, setFiles] = useState(new Array<string>())
    const [year, setYear] = useState("")
    const [month, setMonth] = useState("")


    useEffect(() => {
        payrollService()
    }, []);

    const payrollService = () => {
        //scheiberPayrollService.readWorker((resp) => {
        //    const temp: Array<string> = resp.data.map((value: string) => {
        //        return instanceToInstance(value)
        //    })
        //    setFiles(temp)
        //})
    }

    return <>
        <Formik
            initialValues={{
                year: year,
                month: month,
                files: new Array<FileManagerModel>(),
            }}
            onSubmit={(values, actions) => {
                actions.setSubmitting(true)
                if (values) {
                    const formData = new FormData();
                    // eslint-disable-next-line array-callback-return
                    values.files.map((file, index) => {
                        // Meta data
                        const blob = new Blob([JSON.stringify(file)], {
                            type: 'application/json'
                        })
                        formData.append("meta_data[]", blob)
                        // File
                        if (file._file !== null)
                            formData.append("files[]", file._file);
                    });

                    try {
                        scheiberPayrollService.uploadFile(values.year, values.month, formData, (status, resp) => {
                            if (status === 200 && typeof resp !== "string") {
                                actions.resetForm()
                                actions.setSubmitting(false)
                            }
                        })
                    } catch (error) {
                        console.error(error);
                        actions.setSubmitting(false)
                    }
                }
            }}
            validateOnChange={true}    // if true, gets fucking slow ;)
            validateOnBlur={false}
            validationSchema={formValidationSchema}
        >
            {({values, handleChange, handleBlur, handleSubmit, errors}) => (
                <Form>
                    <Field key={0} name={`year`} label={"Év"} component={GVSelect} >
                        <MenuItem value={"2024"}>2024</MenuItem>
                        <MenuItem value={"2025"}>2025</MenuItem>
                    </Field>
                    <Field key={1} name={`month`} label={"Hónap"} component={GVSelect} >
                        <MenuItem value={"01"}>január</MenuItem>
                        <MenuItem value={"02"}>február</MenuItem>
                        <MenuItem value={"03"}>március</MenuItem>
                        <MenuItem value={"04"}>április</MenuItem>
                        <MenuItem value={"05"}>május</MenuItem>
                        <MenuItem value={"06"}>június</MenuItem>
                        <MenuItem value={"07"}>július</MenuItem>
                        <MenuItem value={"08"}>augusztus</MenuItem>
                        <MenuItem value={"09"}>szeptember</MenuItem>
                        <MenuItem value={"10"}>október</MenuItem>
                        <MenuItem value={"11"}>november</MenuItem>
                        <MenuItem value={"12"}>december</MenuItem>
                    </Field>
                    <FieldArray key={2} name={"files"}>
                        {({insert, remove, push}: any) => (
                            <div>
                                <div>
                                    {values.files.length > 0 &&
                                        values.files.map((file, index) => {
                                            return <div key={index}>
                                                <Box sx={{display: "flex"}}>
                                                    <Field name={`files[${index}].name`}
                                                           component={GVTextField} label={"File name"}/>
                                                    <Field name={`files[${index}].meta`}
                                                           component={GVTextField} label={"Meta data"}/>
                                                    <Button onClick={() => remove(index)}>
                                                        Remove
                                                    </Button>
                                                </Box>
                                            </div>
                                        })}
                                    <Button variant="contained" size="small" color="primary" component="label">
                                        Add files
                                        <input id="file" type="file" hidden multiple onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            if (e.target.files) {
                                                Array.from(e.target.files).forEach((file, index) => {
                                                    push(new FileManagerModel(file))
                                                })
                                            }
                                        }}
                                        />
                                    </Button>
                                </div>
                            </div>
                        )}
                    </FieldArray>
                    <Box style={{width: "200px", margin: "5px"}}>
                        {values.files.length === 0 ?
                            <></>
                            :
                            <Button color={"primary"} variant={"contained"} type={"submit"}>
                                Upload Payrolls
                            </Button>
                        }
                    </Box>
                </Form>
            )}
        </Formik>
        <MyTable myFiles={files} />
    </>
}