import axios, {AxiosInstance, AxiosResponse, InternalAxiosRequestConfig} from "axios";
import oauthService, {authenticator} from "./OauthService";
import {isLocalhost} from "../Tools";

const DEBUG = isLocalhost   // DEBUG or NOT

export const HOST_URL = process.env.REACT_APP_HOST_URL
let baseUrlAdmin = process.env.REACT_APP_BASE_URL_ADMIN
if (DEBUG && authenticator !== "php") {
    baseUrlAdmin = "http://127.0.0.1:9090/"
}
export {baseUrlAdmin}

export class HttpService {

    protected httpClient : AxiosInstance

    protected constructor() {
        this.httpClient = axios.create({
            headers: {

            }
        })
        this.httpClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
            if (authenticator === "keycloak") {
                if (oauthService.isLoggedIn()) {
                    config!.headers!.Authorization = `Bearer ${oauthService.getToken()}`
                    return config
                }
                return config
            }
            /*else if (authenticator === "php") {
                config!.headers!.Authorization = "Basic ***"
            }
             */
            return config
        }, (error) => {
            console.log("Error : " + error)
            return Promise.reject(error)
        })
        this.httpClient.interceptors.response.use((value : AxiosResponse) => {
            if (value.status === 401) {
                if (authenticator === "keycloak") {
                    oauthService.refreshToken((success) => {
                        console.log("token refresh result: " + success)
                    })
                }
                /*else if (authenticator === "php") {

                }*/
            }
            return value
        })
    }

    handleError(error : any, callback : (success : boolean) => any)  {
        const statusCode = error.response ? error.response.status : null
        switch (statusCode) {
            case 401 : {
                console.log('Unauthorized -> refresh token')
                oauthService.refreshToken((success) => {
                    console.log('Refresh token success')
                    callback(success)
                })
                break
            }
            case 404 : {
                console.log('404 - not found')
                break
            }
            case 500 : {
                console.log('500 internal error - bad request')
                break
            }
        }
        return statusCode
    }
}

