import {useEffect, useState} from "react";
import fileManagerService from "../../services/FileManagerService";
import {Box, Button, Drawer, FormGroup, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {instanceToInstance} from "class-transformer";
import {Field, Form, Formik} from "formik";
import {GVCheckbox, GVSelect, GVTextField} from "@gecsevar.hu/gecsevar-formik-fields";
import scheiberPayrollService from "../../services/scheiber.hu/ScheiberPayrollService";
import WorkerModel from "../../models/scheiber.hu/WorkerModel";

type MyTableProps = {
    published: Array<WorkerModel>
    editFunction: (ac: WorkerModel) => void
}

function MyTable(props: MyTableProps) {

    return <TableContainer sx={{overflowX: 'initial'}}>
        <Table sx={{minWidth: 650}} size="small" aria-label="table" stickyHeader>
            <TableHead sx={{
                "& .MuiTableCell-head": {
                    fontWeight: 700
                }
            }}>
                <TableRow>
                    <TableCell align="left">ID</TableCell>
                    <TableCell align="right">Munkatárs neve</TableCell>
                    <TableCell align="left">E-Mail címe</TableCell>
                    <TableCell align="left">Aktív dolgozó</TableCell>
                    <TableCell align="left">Aktív tanár</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.published.map((row, index) => (
                    <TableRow
                        key={'tb' + index}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        hover
                        onClick={() => {
                            props.editFunction(row)
                        }}
                    >
                        <TableCell align="left">{row.id}</TableCell>
                        <TableCell align="right">{row.emailName}</TableCell>
                        <TableCell align="left">{row.emailAddress}</TableCell>
                        <TableCell align="left"><input type="checkbox" defaultChecked={row.activeTeacher}/></TableCell>
                        <TableCell align="left"><input type="checkbox" defaultChecked={row.emailActive}/></TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
}


export function WorkersGrid() {

    const [workers, setWorkers] = useState(new Array<WorkerModel>())
    const [editedWorker, setEditedWorker] = useState(new WorkerModel())

    useEffect(() => {
        loadWorkers()
    }, []);

    const loadWorkers = () => {
        scheiberPayrollService.readWorker((status, resp) => {
            const temp: Array<WorkerModel> = resp.data.map((value: string) => {
                return instanceToInstance(value)
            })
            setWorkers(temp)
        })
    }

    return <Box>
        <MyTable published={workers} editFunction={(ac) => {
            setEditedWorker(ac)
        }}/>
        <Box sx={{display: 'flex', minHeight: '48px', backgroundColor: '#101F33'}} marginTop={1}></Box>
        <Box >
        <Formik
            initialValues={{
                worker: editedWorker,
            }}
            onSubmit={(values, actions) => {
                actions.setSubmitting(true)
                if (values.worker.id === 0) {
                    scheiberPayrollService.createWorker(JSON.stringify(values.worker), (status, resp) => {
                        if (status === 200) {
                            actions.resetForm()
                            setEditedWorker(new WorkerModel())
                            loadWorkers()
                        } else {
                            if (typeof resp.data === "string") {
                            }
                        }
                        actions.setSubmitting(false)
                    })
                } else {
                    fileManagerService.updateContainer(JSON.stringify(values.worker), (status, resp) => {
                        if (status === 200) {
                            actions.resetForm()
                            setEditedWorker(new WorkerModel())
                            loadWorkers()
                        } else {
                            if (typeof resp.data === "string") {
                            }
                        }
                        actions.setSubmitting(false)
                    })
                }
            }}
            //validationSchema={formValidationSchema}
            validateOnChange={true}    // if true, gets fucking slow ;)
            validateOnBlur={false}
            enableReinitialize={true}
        >
            {({values, handleChange}) => (
                <Form>
                    <Box>
                        <FormGroup>
                            <Box sx={{display: {sm: 'block', md: 'flex'}}} marginTop={1}>
                                <Field key={1} name={`worker.id`} label={"ID"} disabled={true} component={GVTextField}/>
                                <Field key={2} name={`worker.emailName`} label={"Név"} component={GVTextField}/>
                                <Field key={3} name={`worker.emailAddress`} label={"E-Mail"} component={GVTextField}/>
                                <Field key={4} name={`worker.activeTeacher`} label={"Aktív dolgozó"} component={GVCheckbox}/>
                                <Field key={5} name={`worker.emailActive`} label={"Aktív tanár"} component={GVCheckbox}/>
                            </Box>
                            <Box sx={{display: 'flex'}} marginTop={1}>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between'}} margin={4}>
                                <Button color={"secondary"} variant={"outlined"} onClick={() => {
                                    setEditedWorker(new WorkerModel())
                                }}>
                                    Reset
                                </Button>
                                <Button color={"primary"} variant={"contained"} type={"submit"}>{values.worker.id === 0 ? "Save" : "Update"}</Button>
                            </Box>
                        </FormGroup>
                    </Box>
                </Form>
            )}
        </Formik>
        </Box>
    </Box>
}