import {Field, FieldArray, Form, Formik} from "formik";
import {Box, Button, MenuItem} from "@mui/material";
import FileManagerModel from "../models/FileManagerModel";
import React, {useEffect, useState} from "react";
import fileManagerService from "../services/FileManagerService";
import {array, number, object, string} from "yup";
import {GVSelect, GVTextField} from "@gecsevar.hu/gecsevar-formik-fields";
import FileManagerContainerModel from "../models/FileManagerContainerModel";
import {instanceToInstance} from "class-transformer";

const formValidationSchema = object({
    fileManager: array().of(object({
        pathId: number().required('Required field!'),
        name: string().required('Required field!'),
        meta: string().required('Required field!')
    }))
});

export function FileManagerForm() {

    const [fileManager, setFileManager] = useState(new Array<FileManagerModel>())
    const [containers, setContainers] = useState(new Array<FileManagerContainerModel>())

    useEffect(() => {
        fileManagerService.getContainers((status, resp) => {
            const temp: Array<FileManagerContainerModel> = resp.data.map((value: string) => {
                return instanceToInstance(value)
            })
            setContainers(temp)
        })
    }, []);

    return <>
        <Formik
            initialValues={{
                fileManager: new Array<FileManagerModel>(),
            }}
            onSubmit={(values, actions) => {
                actions.setSubmitting(true)
                if (values) {
                    const formData = new FormData();
                    // eslint-disable-next-line array-callback-return
                    values.fileManager.map((file, index) => {
                        // Meta data
                        const blob = new Blob([file.toJSON()], {
                            type: 'application/json'
                        })
                        formData.append("meta_data[]", blob)
                        // File
                        if (file._file !== null)
                            formData.append("files[]", file._file);
                    });

                    try {
                        fileManagerService.post(formData, (status, resp) => {
                            if (status === 200 && typeof resp !== "string") {
                                actions.resetForm()
                                actions.setSubmitting(false)
                            }
                        })
                    } catch (error) {
                        console.error(error);
                        actions.setSubmitting(false)
                    }
                }
            }}
            validationSchema={formValidationSchema}
            validateOnChange={true}    // if true, gets fucking slow ;)
            validateOnBlur={true}
        >
            {({values, handleChange, handleBlur, handleSubmit, errors}) => (
                <Form>
                    <FieldArray name={"fileManager"}>
                        {({insert, remove, push}: any) => (
                            <div>
                                <div>
                                    {values.fileManager.length > 0 &&
                                        values.fileManager.map((file, index) => {
                                            return <div key={index}>
                                                <Box sx={{display: "flex"}}>
                                                    <Field name={`fileManager[${index}].id`}
                                                           component={GVTextField} label={"Azonosító"}/>
                                                    <Field name={`fileManager[${index}].pathId`}
                                                           component={GVSelect} label={"Csoport"}>
                                                        {containers.map((item, index) => (
                                                            <MenuItem key={index} value={item.id}>{item.basePath+item.path}</MenuItem>
                                                        ))}
                                                    </Field>
                                                    <Field name={`fileManager[${index}].name`}
                                                           component={GVTextField} label={"File"}/>
                                                    <Field name={`fileManager[${index}].meta`}
                                                           component={GVTextField} label={"Meta data"}/>
                                                    <Button onClick={() => remove(index)}>
                                                        Remove
                                                    </Button>
                                                </Box>
                                            </div>
                                        })}
                                    <Button variant="contained" size="small" color="primary" component="label">
                                        Add files
                                        <input id="file" type="file" hidden multiple onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            if (e.target.files) {
                                                Array.from(e.target.files).forEach((file, index) => {
                                                    push(new FileManagerModel(file))
                                                })
                                            }
                                        }}
                                        />
                                    </Button>
                                </div>
                            </div>
                        )}
                    </FieldArray>
                    <Box style={{width: "200px", margin: "5px"}}>
                        {values.fileManager.length === 0 ?
                            <></>
                            :
                            <Button color={"primary"} variant={"contained"} type={"submit"}>
                                Upload files
                            </Button>
                        }
                    </Box>
                </Form>
            )}
        </Formik>
    </>
}