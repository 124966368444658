import {Box} from "@mui/material";
import {Field, Form, Formik} from "formik";
import {GVCheckbox, GVTextField} from "@gecsevar.hu/gecsevar-formik-fields";
import React from "react";

export function MenuButtonEditor() {

    return <Box>
        <Formik
            initialValues={{
                myDate: Date
            }}
            onSubmit={(values, actions) => {
                console.log("")
            }}>
            <Form>
                <Box sx={{display:'flex'}}>
                    <Field key={1} name={`articleModel.id`} label={"ID"} component={GVTextField}/>
                </Box>
                <Box sx={{display:'flex'}}>
                    <Field key={1} name={`articleModel.id`} label={"ID"} component={GVTextField} disabled sx={{width:50}} />
                    <Field key={2} name={`articleModel.published`} label={"Publish"} component={GVCheckbox} />
                    <Field key={3} name={`articleModel.creator`} label={"Creator"} component={GVTextField} />
                    <Field key={4} name={`articleModel.create_time`} label={"Create Date/Time"} component={GVTextField} />
                </Box>
                <Box sx={{display:'flex'}}>
                    <Field key={3} name={`articleModel.creator`} label={"Creator"} component={GVTextField}/>
                    <Field key={4} name={`articleModel.create_time`} label={"Create Date/Time"} component={GVTextField}/>
                </Box>
            </Form>
        </Formik>
    </Box>
}