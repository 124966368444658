import {baseUrlAdmin, HttpService} from "./HttpService";
import {AxiosResponse} from "axios";

class FileManagerService extends HttpService {

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor() {
        super()
    }

    get(meta: string, callback: (resp: AxiosResponse) => void): Object {
        //this.httpClient.get(baseUrlAdmin + "file_manager.php", {
        this.httpClient.get(baseUrlAdmin + "file-manager/files", {
            params: {
                meta : meta
            },
        })
            .then((resp: AxiosResponse) => {
                callback(resp)
            })
            .catch((error: any) => {
                // handle error
                this.handleError(error, (success) => {
                    if (success) {
                        this.get(meta, callback)
                    }
                })
            })

        return ""
    }
    post(formData: FormData, callback: (status: number, resp: (AxiosResponse | string | null)) => void) {
        this.httpClient.post(baseUrlAdmin + "file-manager/files", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            params: {
            }
        })
            .then((resp : AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch((error: any) => {
                // handle error
                this.handleError(error, (success) => {
                    if (success) {
                        this.post(formData, callback)
                    }
                })
            })
    }

    getContainers(callback: (status: number, resp: AxiosResponse) => void): Object {
        this.httpClient.get(baseUrlAdmin + "file-manager/containers", {
            params: {

            },
        })
            .then((resp: AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch((error: any) => {
                // handle error
                this.handleError(error, (success) => {
                    if (success) {
                        this.getContainers(callback)
                    }
                })
            })

        return ""
    }
    postContainer(data: string, callback: (status: number, resp: AxiosResponse) => void): Object {
        this.httpClient.post(baseUrlAdmin + "file-manager/containers", data, {

        })
            .then((resp: AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch((error: any) => {
                // handle error
                this.handleError(error, (success) => {
                    if (success) {
                        this.postContainer(data, callback)
                    }
                })
            })

        return ""
    }

    updateContainer(data: string, callback: (status: number, resp: AxiosResponse) => void): Object {
        this.httpClient.put(baseUrlAdmin + "file-manager/containers", data, {})
            .then((resp: AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch((error: any) => {
                // handle error
                this.handleError(error, (success) => {
                    if (success) {
                        this.postContainer(data, callback)
                    }
                })
            })

        return ""
    }
}

const fileManagerService = new FileManagerService()
export default fileManagerService