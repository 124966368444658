import {Route, Routes} from "react-router-dom";
import {ArticleEditorPage} from "./ArticleEditorPage";
import {Paper} from "@mui/material";
import {ArticleListPage} from "./ArticleListPage";
import {MenuListPage} from "./MenuListPage";
import {MenuEditorPage} from "./MenuEditorPage";
import {ArticleCategoryListPage} from "./ArticleCategoryListPage";
import {FileManagerListPage} from "./FileManagerListPage";
import {FileManagerForm} from "./FileManagerForm";
import {HelpPage} from "./HelpPage";
import {MenuButtonEditor} from "./MenuButtonEditor";
import {FileManagerContainerListPage} from "./FileManagerContainerListPage";
import {LoginForm} from "./LoginForm";
import {PayrollForm} from "./scheiber.hu/PayrollForm";
import {WorkersGrid} from "./scheiber.hu/WorkersGrid";
import {EmailSenderForm} from "./scheiber.hu/EmailSenderForm";
import {ManagePayrollForm} from "./scheiber.hu/ManagePayrollForm";

export function ContentContainer() {

    return <Paper elevation={3} sx={{marginTop: 1, marginBottom: 1}}>
        <Routes>
            <Route key={"A1"} path={"/"} element={<HelpPage key={1} />}></Route>
            <Route key={"A2"} path={"/article-list"} element={<ArticleListPage key={1}/>}></Route>
            <Route key={"A3"} path={"/article-editor/:id"} element={<ArticleEditorPage key={1}/>}></Route>
            <Route key={"A4"} path={"/menu-list"} element={<MenuListPage key={1}/>}></Route>
            <Route key={"A6"} path={"/menu-editor"} element={<MenuEditorPage key={1}/>}></Route>
            <Route key={"A7"} path={"/menu-button-editor"} element={<MenuButtonEditor key={1}/>}></Route>
            <Route key={"A8"} path={"/article-category"} element={<ArticleCategoryListPage key={1}/>}></Route>
            <Route key={"A9"} path={"/file-manager-containers"} element={<FileManagerContainerListPage key={1}/>}></Route>
            <Route key={"A10"} path={"/file-manager"} element={<FileManagerListPage key={1}/>}></Route>
            <Route key={"A11"} path={"/file-manager-upload"} element={<FileManagerForm key={1}/>}></Route>
            <Route key={"A12"} path={"/php-login"} element={<LoginForm key={1}/>}></Route>

            <Route key={"S01"} path={"/workers"} element={<WorkersGrid />}></Route>
            <Route key={"S02"} path={"/scheiber-payroll"} element={<PayrollForm key={1}/>}></Route>
            <Route key={"S03"} path={"/payroll-mail-form"} element={<EmailSenderForm />}></Route>
            <Route key={"S04"} path={"/manage-payroll"} element={<ManagePayrollForm />}></Route>
        </Routes>
    </Paper>
}