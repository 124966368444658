import React from 'react';
import './App.css';
import {BrowserRouter} from "react-router-dom";
import {MainPage} from "./components/MainPage";

function App() {

    return <React.StrictMode>
        <BrowserRouter>
            <div className="App">
                <MainPage />
            </div>
        </BrowserRouter>
    </React.StrictMode>
}

export default App;
