import Keycloak, {KeycloakConfig, KeycloakInitOptions} from "keycloak-js";
import Cookies from "js-cookie";
import {isLocalhost} from "../Tools";

const DEBUG = isLocalhost   // DEBUG or NOT

export const authenticator = process.env.REACT_APP_AUTHENTICATOR
export const oauthServerUrl = process.env.REACT_APP_OAUTH_SERVER_URL
const oauthRealm = process.env.REACT_APP_OAUTH_REALM!!
const oauthClientId = process.env.REACT_APP_OAUTH_CLIENT_ID!!
let redirectUrl = process.env.REACT_APP_OAUTH_REDIRECT_URL
if (DEBUG) {
    redirectUrl = "http://localhost:3000/"
}
export {redirectUrl}

interface IOauthService {
    appInit(rootRender : () => void) : undefined
    login() : undefined
    logout() : undefined
    getToken() : string | undefined
    getUsername() : string | undefined
    getUserEmail() : string | undefined
    refreshToken(successCallback : (result : boolean) => void) : undefined
    isLoggedIn() : boolean
    registration() : Promise<void>
    isEmailVerified() : undefined
    accountManagement() : undefined
}

class OauthService implements IOauthService {

    config : KeycloakConfig = {
        url: oauthServerUrl,
        realm: oauthRealm,
        clientId: oauthClientId
    }
    initOptions : KeycloakInitOptions = {
        onLoad: 'check-sso',
//        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        redirectUri: redirectUrl,
        checkLoginIframe: false,
    }
    keycloak : Keycloak;

    constructor() {
        this.keycloak = new Keycloak(this.config)
    }
    // Call it from index.tsx
    appInit(rootRender : () => void) : undefined {

        this.keycloak.init(this.initOptions)
            .then((authenticated) => {
                rootRender()
            })
            .catch(console.error)

    }
    login() : undefined {
        this.keycloak.redirectUri = redirectUrl + window.location.pathname
        this.keycloak.login({
        //    redirectUri:
        })
        /*
        redirect...
        .then((authenticated:void) => {
            alert("login res " + authenticated);
        })
        .catch((error) => {
            alert("failure " + error);
        })

         */
    }
    logout() : undefined {
        this.keycloak.logout({redirectUri: redirectUrl})
    }
    getToken() : string | undefined {
        return this.keycloak.token;
    }
    getUsername() : string | undefined {
        return this.keycloak.tokenParsed?.preferred_username;
    }
    getUserEmail() : string | undefined {
        return this.keycloak.tokenParsed?.email;
    }
    refreshToken(successCallback : (result : boolean) => void): undefined {
        this.keycloak.updateToken(5)
            .then(successCallback)
            .catch(error => {
                console.log("fail to refresh")
                //alert(window.location.pathname)
                Cookies.set("ReLoginRestorePrevLocation", window.location.pathname, {expires:1});
                this.login()
            })
    }
    isLoggedIn() : boolean {
        return this.keycloak.authenticated ?? false
    }
    registration() : Promise<void> {
        return this.keycloak.register({
            redirectUri: redirectUrl,
        })
    }
    isEmailVerified() : undefined {
        return this.keycloak.tokenParsed?.email_verified
    }
    accountManagement() : undefined {
        this.keycloak.accountManagement().then(() => {

        })
    }
}

class PhpOauthService implements IOauthService {

    token : string
    constructor() {
        this.token = ""
    }

    accountManagement(): undefined {
        return undefined;
    }

    appInit(rootRender: () => void): undefined {
        rootRender()
    }

    getToken(): string | undefined {
        return undefined;
    }

    getUserEmail(): string | undefined {
        return undefined;
    }

    getUsername(): string | undefined {
        return undefined;
    }

    isEmailVerified(): undefined {
        return undefined;
    }

    isLoggedIn(): boolean {
        return true
        // TODO
        // return this.token.length !== 0;
    }

    login(): undefined {
        return undefined
    }

    logout(): undefined {
        return undefined;
    }

    refreshToken(successCallback: (result: boolean) => void): undefined {
        return undefined;
    }

    registration(): Promise<void> {
        return Promise.resolve(undefined);
    }

}

let oauthService : IOauthService

if (authenticator === "php") {
    oauthService = new PhpOauthService();
} else {
    oauthService = new OauthService();
}
export default oauthService;