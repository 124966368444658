import React, {useEffect, useState} from "react";
import {Box, Button, styled, SvgIconProps, Typography, useTheme} from "@mui/material";
import {TreeItem, treeItemClasses, TreeItemProps, TreeView} from "@mui/x-tree-view";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Label from '@mui/icons-material/Label';
import fileManagerService from "../services/FileManagerService";
import FileManagerModel, {getFilesFor, getPathsFor} from "../models/FileManagerModel";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import {HOST_URL} from "../services/HttpService";
import {plainToClass} from "class-transformer";

declare module 'react' {
    interface CSSProperties {
        '--tree-view-color'?: string;
        '--tree-view-bg-color'?: string;
    }
}

function isEven(value: number){
    if (value%2 === 0)
        return true;
    else
        return false;
}

type StyledTreeItemProps = TreeItemProps & {
    bgColor?: string;
    bgColorForDarkMode?: string;
    color?: string;
    colorForDarkMode?: string;
    labelIcon: React.ElementType<SvgIconProps>;
    labelInfo?: string;
    labelText: string;
};

const StyledTreeItemRoot = styled(TreeItem)(({theme}) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)',
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 0,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
        },
    },
})) as unknown as typeof TreeItem;

const StyledTreeItem = React.forwardRef(function StyledTreeItem(
    props: StyledTreeItemProps,
    ref: React.Ref<HTMLLIElement>,
) {
    const theme = useTheme();
    const {
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        labelText,
        colorForDarkMode,
        bgColorForDarkMode,
        ...other
    } = props;

    const styleProps = {
        '--tree-view-color': theme.palette.mode !== 'dark' ? color : colorForDarkMode,
        '--tree-view-bg-color':
            theme.palette.mode !== 'dark' ? bgColor : bgColorForDarkMode,
    };

    return (
        <StyledTreeItemRoot
            label={
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'space-between',
                        p: 0.5,
                        pr: 0,
                    }}
                >
                    <Box component={LabelIcon} color="inherit" sx={{color:'black', display: 'flex', mr: 1, alignItems: 'stretch'}}/>
                    <Typography variant="body2" sx={{fontWeight: 'inherit'}}>
                        {labelText}
                    </Typography>
                    <Typography variant="body2" color="inherit" sx={{color:'black', mr: 1}}>
                        {labelInfo}
                    </Typography>
                </Box>
            }
            style={styleProps}
            {...other}
            ref={ref}
        />
    );
});

export function FileManagerListPage() {

    const [images, setImages] = useState(new Array<string>())
    const [fileManager, setFileManager] = useState(new Array<FileManagerModel>())
    const [files, setFiles] = useState<FileList | null>(null);

    function loadFilesData() {
        fileManagerService.get("", (resp) => {
            const temp: Array<FileManagerModel> = resp.data.map((value: string) => {
                return plainToClass(FileManagerModel, value)
            })
            setFileManager(temp)
        })
    }
    let mySet = new Set<number>();
    mySet.add(1).add(2).add(3).add(4).add(5).add(6);

    useEffect(() => {
        loadFilesData()
    }, []);

    return <Box sx={{display: 'block', alignItems: 'center', p: 0.5, pr: 0, width:'inherit'}}>
            <TreeView
                aria-label="files_manager"
                defaultExpanded={['3']}
                defaultCollapseIcon={<ArrowDropDownIcon/>}
                defaultExpandIcon={<ArrowRightIcon/>}
                defaultEndIcon={<div style={{width: 24}}/>}
                sx={{flexGrow: 1, overflowY: 'auto'}}
            >
                {Array.from(getPathsFor(fileManager)).map((path, index) => (
                    <StyledTreeItem key={index} nodeId={path} labelText={path} labelIcon={Label}>
                        {getFilesFor(path, fileManager).map((value, index) => (
                            <Box key={index} sx={{display:'flex', color:'black', justifyContent:'space-between', bgcolor:isEven(index) ? 'lightBlue':'lightGray'}}>
                                <StyledTreeItem nodeId={path + String(index)} labelInfo={value.name} labelText={""}
                                                labelIcon={value.isImage() ? ImageOutlinedIcon : ArticleOutlinedIcon}

                                /><Typography variant={'body2'} sx={{m:1}}>{value.meta}</Typography>
                                {value.isImage() ? <img
                                        src={HOST_URL+`${value.path}${value.name}`}
                                        alt={"mini"}
                                        loading="lazy"
                                        width="50" height="50"
                                    />
                                    :
                                    <></>}
                                <Box sx={{alignContent: 'center', m:1}}>
                                <Button variant={"contained"} size={"small"} color={"error"}>Delete</Button>
                                </Box>
                            </Box>
                        ))
                        }
                    </StyledTreeItem>
                ))
                }
            </TreeView>
        </Box>
}
/*
        <ImageList sx={{width: 500, height: 450}} cols={3} rowHeight={164}>
            {images.map((item) => (
                <ImageListItem key={item}>
                    <img
                        srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        src={`${item}?w=164&h=164&fit=crop&auto=format`}
                        alt={item}
                        loading="lazy"
                        onClick={() => {
                            console.log(item)
                        }}
                    />
                    <ImageListItemBar
                        title={"item.title"}
                        subtitle={"item.author"}
                        actionIcon={
                            <IconButton
                                sx={{color: 'rgba(255, 255, 255, 0.54)'}}
                                aria-label={`info about ${item}`}
                            >
                                <InfoIcon/>
                            </IconButton>
                        }
                    />
                </ImageListItem>
            ))}
        </ImageList>
 */