import React from "react";
import {Box, Collapse, Drawer, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {NavigationItem} from "../models/HeaderMenuModel";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

function getSubMenuFor(menuMap: Array<NavigationItem>, menuId: string): Array<NavigationItem> {

    let inZone = false;
    const temp: Array<NavigationItem> = [];
    // eslint-disable-next-line array-callback-return
    menuMap.map((value) => {
        if (value.id === menuId) {
            inZone = true
        } else if (inZone && value.level === "submenu") {
            temp.push(value)
        } else if (inZone && value.level !== "submenu") {
            inZone = false
        }
    })

    return temp
}

type MenuListItemProps = {
    item: NavigationItem
    selectionId: string
    setSelectionId: (id: string) => void
    setDrawerState: (state: boolean) => void
    isSubmenu: boolean
}

function MenuListItem(props: MenuListItemProps) {

    return (
        <Link key={props.item.id} style={{textDecoration: 'none'}} onClick={() => {
            if (props.item.articleId === null) {
                // navigate to internal page
                //navigate(ArticleListPath + props.item.articleCategory)
            } else {
                // navigate to /{path}/{id} HTML page
                //navigate(ContentPath + props.item.articleId)
            }
            props.setSelectionId(props.item.id)
            props.setDrawerState(false)
        }}>
            <ListItem disablePadding key={props.item.id} sx={{
                color: 'white',
                ':hover': {
                    color: '#fdc800'
                }
            }}>
                <ListItemButton selected={props.selectionId === props.item.id}
                                sx={{
                                    '&.Mui-selected': {
                                        background: '#004167',
                                        color: 'lightGreen'
                                    },
                                    '&.Mui-focusVisible': {
                                        background: '#2e8b57'
                                    },
                                    ':hover': {
                                        background: '#002147'
                                    },
                                    pl: 0,
                                }}
                >
                    <ListItemIcon>{}</ListItemIcon>
                    <ListItemText sx={{marginLeft: props.isSubmenu ? 2 : 0}}>{props.item.label}</ListItemText>
                </ListItemButton>
            </ListItem>
        </Link>
    )
}

type MenuItemProps = {
    item: NavigationItem
    selectionId: string
    setSelectionId: (id: string) => void
        setDrawerState: (state: boolean) => void
    menuMap: Array<NavigationItem>
}

function MenuItemRow(props: MenuItemProps) {

    const [open, setOpen] = React.useState(true)

    const handleClick = () => {
        setOpen(!open);
    }
    if (props.item.level === 'collapse') {
        return <>
            <Link key={props.item.id} style={{textDecoration: 'none'}} onClick={handleClick}>
                <ListItem disablePadding key={props.item.id} sx={{
                    color: 'white',
                    ':hover': {
                        color: '#fdc800'
                    }
                }}>
                    <ListItemButton selected={props.selectionId === props.item.id}
                                    sx={{
                                        '&.Mui-selected': {
                                            background: '#004167',
                                            color: 'lightGreen'
                                        },
                                        '&.Mui-focusVisible': {
                                            background: '#2e8b57'
                                        },
                                        ':hover': {
                                            background: '#002147'
                                        },
                                        pl: 0,
                                    }}
                    >
                        <ListItemIcon>{}</ListItemIcon>
                        <ListItemText>{props.item.label}</ListItemText>
                        {open ? <ExpandLess/> : <ExpandMore/>}
                    </ListItemButton>
                </ListItem>
            </Link>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {getSubMenuFor(props.menuMap, props.item.id).map((value, index) => (
                        <MenuListItem key={index} item={value} selectionId={props.selectionId} setSelectionId={(id: string) => {
                            props.setSelectionId(id)
                        }} setDrawerState={(state: boolean) => {
                            props.setDrawerState(state)
                        }} isSubmenu={true}/>
                    ))}
                </List>
            </Collapse>
        </>
    } else {
        return (
            <MenuListItem item={props.item} selectionId={props.selectionId} setSelectionId={(id: string) => {
                props.setSelectionId(id)
            }} setDrawerState={(state: boolean) => {
                props.setDrawerState(state)
            }}
                          isSubmenu={false}/>
        )
    }
}

type WysiwygMenuProps = {
    menuMap: Array<NavigationItem>
}

export function WysiwygMenu(props: WysiwygMenuProps) {

    return <Drawer
        anchor={'right'}
        open={true}
        onClose={(_: React.SyntheticEvent<{}, Event>) => {

        }}
        sx={{flexShrink: 1,}}
        variant={"permanent"}
    >
        <Box sx={{
            color: 'white',
            background: 'linear-gradient(to top, #002147aa, #002147dd), url(/images/background/aula_leader.jpg) no-repeat top center',
            height: '100%'
        }}>
            <List key={"menu"} disablePadding>
                <ListItem sx={{py: 2, px: 3}}>
                    <ListItemText>WYSIWYG Menu</ListItemText>
                </ListItem>
                {props.menuMap.filter(value => {
                    return value.level !== "submenu"
                }).map((value) => (
                    <MenuItemRow key={value.id} item={value} menuMap={props.menuMap} selectionId={"selectionId"} setSelectionId={(_: string) => {
                        //setSelectionId(id)
                    }} setDrawerState={(_: boolean) => {
                        //setDrawerState(state)
                    }}/>
                ))}
            </List>
        </Box>
    </Drawer>
}