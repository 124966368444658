class WorkerModel {

    id: number = 0
    emailAddress: string = ""
    emailName: string = ""
    emailAttachment: string = ""
    emailActive: boolean = false
    teacherName: string = ""
    teacherProfession: string = ""
    activeTeacher: boolean = false
}

export default WorkerModel