import {baseUrlAdmin, HttpService} from "./HttpService";
import {AxiosResponse} from "axios";

class ArticleService extends HttpService {

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor() {
        super()
    }

    get(id: number, callback: (resp: AxiosResponse) => void): Object {
        this.httpClient.get(baseUrlAdmin + "article/auth", {
            params: {
                id : id
            },
        })
            .then((resp: AxiosResponse) => {
                callback(resp)
            })
            .catch((error: any) => {
                // handle error
                this.handleError(error, (success) => {
                    if (success) {
                        this.get(id, callback)
                    }
                })
            })

        return ""
    }

    getAll(callback: (resp: AxiosResponse) => void): Object {
        this.httpClient.get(baseUrlAdmin + "article/auth", {
            params: {
            },
        })
            .then((resp: AxiosResponse) => {
                callback(resp)
            })
            .catch((error: any) => {
                this.handleError(error, (success) => {
                    if (success) {
                        this.getAll(callback)
                    }
                })
            })

        return ""
    }

    update(content : string, callback : (status: number, resp: AxiosResponse | string | null) => void) {
        this.httpClient.put(baseUrlAdmin + "article/auth", content, {
            params: {
            }
        })
            .then((resp : AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch((error: any) => {
                // handle error
                this.handleError(error, (success) => {
                    if (success) {
                        this.update(content, callback)
                    }
                })
            })
    }
    createNewArticle(callback : (status: number, resp: AxiosResponse | string | null) => void) {
        this.httpClient.post(baseUrlAdmin + "article/auth", "",{

        })
            .then((resp : AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch((error: any) => {
                // handle error
                this.handleError(error, (success) => {
                    if (success) {
                        this.createNewArticle(callback)
                    }
                })
            })
    }
}

const articleService = new ArticleService()
export default articleService