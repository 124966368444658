import {baseUrlAdmin, HttpService} from "./HttpService";
import {AxiosResponse} from "axios/index";

class UploadService extends HttpService {

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor() {
        super()
    }

    get(id: number, callback: (resp: AxiosResponse) => void): Object {
        this.httpClient.get(baseUrlAdmin + "_.php", {
            params: {
                id : id
            },
        })
            .then((resp: AxiosResponse) => {
                callback(resp)
            })
            .catch((error: any) => {
                // handle error
                this.handleError(error, (success) => {
                    if (success) {
                        this.get(id, callback)
                    }
                })
            })

        return ""
    }

// curl --location --request POST 'https://admin.scheiber.hu/api/tinymce/image_upload.php' --trace-ascii trace-req-dump.log --form 'fname="Albert"' --form 'lname="Eienstine"' --form 'subject="Mathematics"' --form 'subject="Physics"' --form 'profilePic=@"C:/Users/AstrA/Pictures/photo_2023-01-20_18-03-54.jpg"'
    post(fileName: Element | null, formData: FormData, callback: (status: number, resp: (AxiosResponse | string | null)) => void) {
        this.httpClient.post(baseUrlAdmin + "/tinymce/image_upload.php", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            params: {
            }
        })
            .then((resp : AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch((error: any) => {
                // handle error
                this.handleError(error, (success) => {
                    if (success) {
                        this.post(fileName, formData, callback)
                    }
                })
            })
    }
}

const uploadService = new UploadService()
export default uploadService