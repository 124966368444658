import {Editor} from "@tinymce/tinymce-react";
import React, {useEffect, useState} from "react";
import {Alert, Box, Button, FormGroup, IconButton, InputAdornment, Snackbar, Typography} from "@mui/material";
import articleService from "../services/ArticleService";
import {FileSelectorDialog} from "./FileSelectorDialog";
import {useParams} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import ArticleModel from "../models/ArticleModel";
import {ClearIcon, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {array, number, object, string} from "yup";
import uploadService from "../services/UploadService";
import {HOST_URL} from "../services/HttpService";
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import articleCategoryService from "../services/ArticleCategoryService";
import {plainToInstance} from "class-transformer";
import ArticleCategoryModel from "../models/ArticleCategoryModel";
import {GVCheckbox, GVDateTimePicker, GVSelect, GVTextField} from "@gecsevar.hu/gecsevar-formik-fields";

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))
let selectedFile = ""
let selectedFileMeta = {}
let fileBrowserOpen = false

const formValidationSchema = object({
    articleModel: object({
        id: number().moreThan(0, "Could not be zero!!"),
        categoriesArray: array().min(1, "Needs at least one category!").test("Article type", "Only ONE of them could be selected: article OR article-head", values => {
            return !(!!(values?.includes("article") && values?.includes("article-head")))
        }),
        creator: string(),
        create_time: string(),
        title: string().max(100),
        description: string().max(150),
        content: string(),
    })
});

type ArticleEditorPageProps = {}

// https://www.tiny.cloud/docs/integrations/react/
export function ArticleEditorPage(_: ArticleEditorPageProps) {

    const param = useParams();

    const [loaded, setLoaded] = useState(false)
    const [article, setArticle] = useState<ArticleModel>(new ArticleModel())
    const [openPopup, setOpenPopup] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = useState("")
    const [metaType, setMetaType] = useState("")
    const [articleCategories, setArticleCategories] = useState<Array<string>>([])

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    }

    const handlePopupClose = (value: string, meta: {}) => {
        fileBrowserOpen = false
        setOpenPopup(false)
        selectedFile = value
        selectedFileMeta = meta
    }

    const remoteFilePicker = async function (callback: (value: string, meta?: (Record<string, any> | undefined)) => void, value: string, meta: Record<string, any>) {
        selectedFile = ""
        fileBrowserOpen = true
        setSelectedValue("")
        setMetaType(meta.filetype)
        setOpenPopup(true)

        let keepPoll = fileBrowserOpen
        while (keepPoll) {
            // eslint-disable-next-line no-loop-func
            await sleep(300).then((_) => {
                    if (!fileBrowserOpen) {
                        callback(selectedFile, selectedFileMeta)
                        return true
                    } else {
                        return false
                    }
                }
            )
        }
    }

    const remoteIMagePickerForText = async function (callback: (value: string) => void) {

        selectedFile = ""
        fileBrowserOpen = true
        setSelectedValue("")
        setMetaType("image")
        setOpenPopup(true)

        let keepPoll = fileBrowserOpen
        while (keepPoll) {
            // eslint-disable-next-line no-loop-func
            await sleep(300).then((_) => {
                    if (!fileBrowserOpen) {
                        callback(selectedFile)
                        return true
                    } else {
                        return false
                    }
                }
            )
        }
    }

    useEffect(() => {
        articleCategoryService.get((status, resp) => {
            const temp: Array<string> = resp.data.map((value: string) => {
                return plainToInstance(ArticleCategoryModel, value).name
            })
            setArticleCategories(temp)
        })
        articleService.get(Number(param["id"]), (resp) => {
            const temp = ArticleModel.fromJSON(resp.data)
            setArticle(temp)
            setLoaded(true)
        })
    }, [param]);

    if (!loaded) {
        return (<Box>
                Page loading...
            </Box>
        )
    } else {
        return <>
            <Box>
                <FileSelectorDialog
                    key={"myDialog"}
                    selectedValue={selectedValue}
                    metaType={metaType}
                    open={openPopup}
                    onClose={handlePopupClose}
                />
                <Typography variant={"h5"} fontStyle={"normal"} margin={0} align={"center"} width={"100%"} marginBottom={1}>
                    Article Editor
                </Typography>
                <Formik
                    initialValues={{
                        articleModel: article,
                    }}
                    onSubmit={(values, actions) => {
                        actions.setSubmitting(true)
                        articleService.update(values.articleModel.toJSON(), (status, resp) => {
                            if (status === 200) {
                                setOpen(true)
                            } else {
                                if (typeof resp === "string") {
                                    setOpen(true)
                                }
                            }
                            actions.setSubmitting(false);
                        })
                    }}
                    onValidationError={(errors: any) => {
                        console.log(errors?.articleModel?.categoriesArray)
                    }}
                    validationSchema={formValidationSchema}
                    validateOnChange={false}    // if true, gets fucking slow ;)
                    validateOnBlur={false}
                    enableReinitialize={false}
                >
                    {({values, handleChange, setFieldValue, errors}) => (
                        <Form>
                            <Box>
                                <FormGroup>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Snackbar
                                            anchorOrigin={{vertical: "top", horizontal: "center"}}
                                            autoHideDuration={4000}
                                            open={open}
                                            onClose={handleClose}
                                            key={"vertical:top"}
                                        ><Alert onClose={handleClose} sx={{width: '100%'}}>
                                            Adatmentés sikeres!
                                        </Alert>
                                        </Snackbar>
                                        <Box sx={{display: 'flex', justifyContent: 'center'}} margin={1}>
                                            <Button color={"primary"} variant={"contained"} type={"submit"}>SAVE ARTICLE</Button>
                                        </Box>
                                        <Box sx={{display: {sm: 'block', md: 'flex'}}} marginTop={1}>
                                            <Field key={1} name={`articleModel.id`} label={"ID"} component={GVTextField} sx={{width: 50}} disabled/>
                                            <Field key={2} name={`articleModel.published`} label={"Publish"} component={GVCheckbox}/>
                                            <Field key={3} name={`articleModel.path`} label={"Path"} component={GVTextField}/>
                                            <Field key={4} name={`articleModel.creator`} label={"Creator"} component={GVTextField}/>
                                            <Field key={5} name={`articleModel.createTime`} label={"Create Date/Time"} component={GVDateTimePicker}/>
                                        </Box>
                                        <Box sx={{width: "maxWidth", display: {sm: 'block', md: 'flex'}}} marginTop={1}>
                                            <Field key={6} name={`articleModel.title`} label={"Title of the article"}
                                                   sx={{minWidth: "400px"}}
                                                   component={GVTextField}/>
                                            <Field key={7} name={`articleModel.categoriesArray`} label={"Categories"} component={GVSelect}
                                                   options={articleCategories}
                                                   isMultiSelect={true}
                                            />
                                        </Box>
                                        <Box sx={{display: 'flex'}} marginTop={1}>
                                            <Field key={8} name={`articleModel.description`} label={"Small description"}
                                                   multiline rows={3}
                                                   style={{width: "400px", margin: "5px"}}
                                                   component={GVTextField}/>
                                            <Field key={9} name={`articleModel.cardImageUrl`} label={"Mini / Maxi Card image"} component={GVTextField}
                                                   InputProps={{
                                                       startAdornment: (
                                                           <InputAdornment position="start">
                                                               <IconButton onClick={() => {
                                                                   setFieldValue(`articleModel.cardImageUrl`, "")
                                                               }}>
                                                                   <ClearIcon/>
                                                               </IconButton>
                                                           </InputAdornment>
                                                       ),
                                                       endAdornment: (
                                                           <Button onClick={() => {
                                                               remoteIMagePickerForText((value) => {
                                                                   setFieldValue(`articleModel.cardImageUrl`, value)
                                                               })
                                                           }}>
                                                               <PhotoOutlinedIcon/>
                                                           </Button>
                                                       ),
                                                   }}
                                            />
                                            <Field key={10} name={`articleModel.bannerImageUrl`} label={"Article top banner image"} component={GVTextField}
                                                   InputProps={{
                                                       startAdornment: (
                                                           <InputAdornment position="start">
                                                               <IconButton onClick={() => {
                                                                   setFieldValue(`articleModel.bannerImageUrl`, "")
                                                               }}>
                                                                   <ClearIcon/>
                                                               </IconButton>
                                                           </InputAdornment>
                                                       ),
                                                       endAdornment: (
                                                           <Button onClick={() => {
                                                               remoteIMagePickerForText((value) => {
                                                                   setFieldValue(`articleModel.bannerImageUrl`, value)
                                                               })
                                                           }}>
                                                               <PhotoOutlinedIcon/>
                                                           </Button>
                                                       ),
                                                   }}
                                            />
                                        </Box>
                                        <Editor
                                            tinymceScriptSrc={"/tinymce/js/tinymce/tinymce.min.js"}
                                            onInit={(evt, editor) => {
                                                // editorRef.current = editor as Editor
                                            }}
                                            init={{
                                                height: 500,
                                                menubar: true,
                                                plugins: [
                                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                ],
                                                toolbar: 'undo redo | blocks | ' +
                                                    'bold italic forecolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    'code | image | ' +
                                                    'removeformat | help',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                language: 'hu_HU',

                                                images_upload_handler: (blobInfo, progress) => new Promise((resolve, reject) => {
                                                    /* no matter what you upload, we will turn it into TinyMCE logo :)*/
                                                    var formData = new FormData();
                                                    var imageFile = document.querySelector('#file');
                                                    formData.append(blobInfo.filename(), blobInfo.blob());
                                                    uploadService.post(imageFile, formData, (status, resp) => {
                                                        progress(50)
                                                        if (status === 200 && typeof resp !== "string") {
                                                            resolve(resp?.data["location"])
                                                        } else {
                                                            reject("error")
                                                        }
                                                    })
                                                }),
                                                images_upload_base_path: '/',
                                                images_upload_url: '/api/tinymce/image_upload.php',
                                                document_base_url: HOST_URL,
                                                relative_urls: false,
                                                file_picker_callback: (callback, value, meta) => {
                                                    remoteFilePicker(callback, value, meta)
                                                    /*
                                                                                                        // Provide file and text for the link dialog
                                                                                                        if (meta.filetype === 'file') {
                                                                                                            callback('mypage.html', {text: 'My text'});
                                                                                                        }
                                                                                                        // Provide image and alt text for the image dialog
                                                                                                        if (meta.filetype === 'image') {
                                                                                                            callback(value, {alt: 'My alt text'})
                                                                                                        }
                                                                                                        // Provide alternative source and posted for the media dialog
                                                                                                        if (meta.filetype === 'media') {
                                                                                                            callback('movie.mp4', {source2: 'alt.ogg', poster: 'image.jpg'});
                                                                                                        }
                                                     */
                                                },
                                                image_advtab: true,
                                                image_list: (success: (items: any) => any) => {
                                                    success([
                                                        {title: 'Dog 1', value: 'mydog.jpg'},
                                                        {title: 'Cat 1', value: 'mycat.gif'}
                                                    ]);
                                                }
                                            }}
                                            onEditorChange={(e) => {
                                                handleChange({target: {name: 'articleModel.content', value: e}})
                                            }}
                                            value={values.articleModel.content}
                                        />
                                    </LocalizationProvider>
                                </FormGroup>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    }
}