import {Box, Button, FormGroup, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import React, {useEffect, useState} from "react";
import {instanceToInstance} from "class-transformer";
import {Field, Form, Formik} from "formik";
import {GVSelect, GVTextField} from "@gecsevar.hu/gecsevar-formik-fields";
import {object, string} from "yup";
import fileManagerService from "../services/FileManagerService";
import FileManagerContainerModel from "../models/FileManagerContainerModel";

type MyTableProps = {
    published: Array<FileManagerContainerModel>
    editFunction: (ac: FileManagerContainerModel) => void
}

function MyTable(props: MyTableProps) {

    return <Paper sx={{width: '100%', overflow: 'hidden'}}>
        <TableContainer sx={{overflowX: 'initial'}}>
            <Table sx={{minWidth: 650}} size="small" aria-label="table" stickyHeader>
                <TableHead sx={{
                    "& .MuiTableCell-head": {
                        fontWeight: 700
                    }
                }}>
                    <TableRow>
                        <TableCell align="left">ID</TableCell>
                        <TableCell align="right">Base path</TableCell>
                        <TableCell align="left">User path</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.published.map((row, index) => (
                        <TableRow
                            key={'tb' + index}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            hover
                            onClick={() => {
                                props.editFunction(row)
                            }}
                        >
                            <TableCell align="left">{row.id}</TableCell>
                            <TableCell align="right">{row.basePath}</TableCell>
                            <TableCell align="left">{row.path}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Paper>
}

const formValidationSchema = object({
    fileManagerContainer: object({
        path: string().required("Must be something!"),
        basePath: string().required("Select base path!"),
    }),
});

export function FileManagerContainerListPage() {

    const [container, setContainers] = useState(new Array<FileManagerContainerModel>())
    const [editedContainer, setEditedContainer] = useState(new FileManagerContainerModel())

    useEffect(() => {
        loadFileManagerContainers()
    }, []);

    const loadFileManagerContainers = () => {
        fileManagerService.getContainers((status, resp) => {
            const temp: Array<FileManagerContainerModel> = resp.data.map((value: string) => {
                return instanceToInstance(value)
            })
            setContainers(temp)
        })
    }

    return <Box>
        <MyTable published={container} editFunction={(ac) => {
            setEditedContainer(ac)
        }}/>
        <Box sx={{display: 'flex', minHeight:'48px', backgroundColor: '#101F33'}} marginTop={1}></Box>
        <Formik
            initialValues={{
                fileManagerContainer: editedContainer,
            }}
            onSubmit={(values, actions) => {
                actions.setSubmitting(true)
                if (values.fileManagerContainer.id === 0) {
                    fileManagerService.postContainer(JSON.stringify(values.fileManagerContainer), (status, resp) => {
                        if (status === 200) {
                            actions.resetForm()
                            setEditedContainer(new FileManagerContainerModel())
                            loadFileManagerContainers()
                        } else {
                            if (typeof resp.data === "string") {
                            }
                        }
                        actions.setSubmitting(false)
                    })
                } else {
                    fileManagerService.updateContainer(JSON.stringify(values.fileManagerContainer), (status, resp) => {
                        if (status === 200) {
                            actions.resetForm()
                            setEditedContainer(new FileManagerContainerModel())
                            loadFileManagerContainers()
                        } else {
                            if (typeof resp.data === "string") {
                            }
                        }
                        actions.setSubmitting(false)
                    })
                }
            }}
            validationSchema={formValidationSchema}
            validateOnChange={true}    // if true, gets fucking slow ;)
            validateOnBlur={false}
            enableReinitialize={true}
        >
            {({values, handleChange}) => (
                <Form>
                    <Box>
                        <FormGroup>
                            <Box sx={{display: {sm: 'block', md: 'flex'}}} marginTop={1}>
                                <Field key={1} name={`fileManagerContainer.id`} label={"ID"} disabled={true} component={GVTextField}/>
                                <Field key={2} name={`fileManagerContainer.basePath`} label={"Base path"} component={GVSelect}>
                                    <MenuItem key={0} value={"/files/images/"}>/files/images/</MenuItem>
                                    <MenuItem key={1} value={"/files/documents/"}>/files/documents/</MenuItem>
                                </Field>
                                <Field key={3} name={`fileManagerContainer.path`} label={"user path"} component={GVTextField}/>
                            </Box>
                            <Box sx={{display: 'flex'}} marginTop={1}>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between'}} margin={4}>
                                <Button color={"secondary"} variant={"outlined"} onClick={() => {
                                    setEditedContainer(new FileManagerContainerModel())
                                }}>
                                    Reset category
                                </Button>
                                <Button color={"primary"} variant={"contained"} type={"submit"}>{values.fileManagerContainer.id === 0 ? "Save":"Update"}</Button>
                            </Box>
                        </FormGroup>
                    </Box>
                </Form>
            )}
        </Formik>
    </Box>
}