import {Box, Button, FormGroup} from "@mui/material";
import {Field, Form, Formik} from "formik";
import React from "react";
import oauthService from "../services/OauthService";
import {GVTextField} from "@gecsevar.hu/gecsevar-formik-fields";

export function LoginForm() {

    return <Box sx={{width: "maxWidth", display: 'flex'}} margin={3}>
        <Formik
            initialValues={{userName: "", password: ""}}
            onSubmit={(values) => {
                oauthService.login()
            }}>
            {({values, handleChange, setFieldValue}) => (
                <Form>
                    <Box>
                        <FormGroup>
                            <Box sx={{width: "maxWidth", display: 'flex'}} marginTop={1}>
                                <Field key={1} name={`userName`} label={"User name"}
                                       sx={{minWidth: "400px"}}
                                       component={GVTextField}/>
                                <Field key={2} name={`password`} label={"Password"} type={"password"}
                                       sx={{minWidth: "400px"}}
                                       component={GVTextField}/>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'center'}} margin={1}>
                                <Button color={"primary"} variant={"contained"} type={"submit"}>Login</Button>
                            </Box>
                        </FormGroup>
                    </Box>
                </Form>
                )}
        </Formik>
    </Box>
}