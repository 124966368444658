import {AxiosResponse} from "axios";
import {baseUrlAdmin, HttpService} from "../HttpService";

class ScheiberPayrollService extends HttpService {

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor() {
        super()
    }

    createWorker(formData: string, callback: (status: number, resp: AxiosResponse) => void) {
        this.httpClient.post(baseUrlAdmin + "payroll/worker", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            params: {

            },
        })
            .then((resp: AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch((error: any) => {
                // handle error
                this.handleError(error, (success) => {
                    if (success) {
                        this.createWorker(formData, callback)
                    }
                })
            })
    }
    readWorker(callback: (status: number, resp: AxiosResponse) => void) {
        //this.httpClient.get(baseUrlAdmin + "file_manager.php", {
        this.httpClient.get(baseUrlAdmin + "payroll/worker", {
            params: {
            },
        })
            .then((resp: AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch((error: any) => {
                // handle error
                this.handleError(error, (success) => {
                    if (success) {
                        this.readWorker(callback)
                    }
                })
            })
    }
    updateWorker(callback: (status: number, resp: AxiosResponse) => void) {
        this.httpClient.put(baseUrlAdmin + "payroll/worker", {
            params: {

            },
            body: JSON.stringify({})
        })
            .then((resp: AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch((error: any) => {
                // handle error
                this.handleError(error, (success) => {
                    if (success) {
                        this.updateWorker(callback)
                    }
                })
            })
    }
    deleteWorker(callback: (status: number, resp: AxiosResponse) => void) {
        this.httpClient.delete(baseUrlAdmin + "payroll/worker", {
            params: {

            },
        })
            .then((resp: AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch((error: any) => {
                // handle error
                this.handleError(error, (success) => {
                    if (success) {
                        this.deleteWorker(callback)
                    }
                })
            })
    }
    readFile(callback: (status: number, resp: AxiosResponse) => void): Object {
        //this.httpClient.get(baseUrlAdmin + "file_manager.php", {
        this.httpClient.get(baseUrlAdmin + "payroll/upload", {
            params: {
            },
        })
            .then((resp: AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch((error: any) => {
                // handle error
                this.handleError(error, (success) => {
                    if (success) {
                        this.readFile(callback)
                    }
                })
            })

        return ""
    }
    uploadFile(year: string, month: string, formData: FormData, callback: (status: number, resp: AxiosResponse) => void) {
        this.httpClient.post(baseUrlAdmin + "payroll/upload", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            params: {
                year: year,
                month: month
            }
        })
            .then((resp: AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch((error: any) => {
                // handle error
                this.handleError(error, (success) => {
                    if (success) {
                        this.uploadFile(year, month, formData, callback)
                    }
                })
            })
    }
    deleteFile(path: string, callback: (status: number, resp: AxiosResponse) => void) {
        this.httpClient.delete(baseUrlAdmin + "payroll/upload", {
            params: {
                path: path
            }
        })
            .then((resp: AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch((error: any) => {
                // handle error
                this.handleError(error, (success) => {
                    if (success) {
                        this.deleteFile(path, callback)
                    }
                })
            })
    }

    getMailer(callback: (resp: AxiosResponse) => void) {
        this.httpClient.get(baseUrlAdmin + "payroll/mailer",{
            params: {
                year: '',
                month: ''
            },
        })
            .then((resp: AxiosResponse) => {
                callback(resp)
            })
            .catch((error: any) => {
                // handle error
                this.handleError(error, (success) => {
                    if (success) {
                        this.getMailer(callback)
                    }
                })
            })
    }
    postMailer(callback: (resp: AxiosResponse) => void) {
        this.httpClient.post(baseUrlAdmin + "payroll/mailer", {
            params: {
                year: '',
                month: ''
            },
        })
            .then((resp: AxiosResponse) => {
                callback(resp)
            })
            .catch((error: any) => {
                // handle error
                this.handleError(error, (success) => {
                    if (success) {
                        this.postMailer(callback)
                    }
                })
            })
    }

}

const scheiberPayrollService = new ScheiberPayrollService()
export default scheiberPayrollService