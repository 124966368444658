import {plainToClass} from "class-transformer";

class ArticleModel {
    id : number = 0
    published : boolean = false
    path = ""
    categories = ""
    categoriesArray : Array<string> = []
    creator : string = ""
    createTime : Date = new Date()
    title : string = ""
    cardImageUrl : string = ""
    bannerImageUrl : string = ""
    description : string = ""
    content : string = ""

    toJSON(): string {
        this.categories = this.categoriesArray.toString()
        const { categoriesArray: _, ...props } = this;
        return JSON.stringify(props)
    }

    static fromJSON(data: string) {
        let temp = plainToClass(ArticleModel, data.at(0))
        if (temp.categories.length === 0) {
            temp.categoriesArray.push("article")
        } else {
            temp.categoriesArray = temp.categories.split(',')
        }

        return temp
    }

}

export default ArticleModel

/*

Title
Body
Tags
 (published)

Last saved Not saved yet
    Author admin
    Revision log message

    Briefly describe the changes you have made.

Menu settings (Not in menu)
    Provide a menu link
Comment settings
        Comments
    Open
        Users with the "Post comments" permission can post comments.
    Closed
        Users cannot post comments.

URL alias (No alias)
    URL alias
Specify an alternative path by which this data can be accessed. For example, type "/about" when writing an about page.
    Authoring information (By admin (1) on 2023-11-22)
    Authored by
        The username of the content author.
    Authored on
        Date
        Time
        The time that the node was created.
    Promotion options (Promoted to front page)
        Promoted to front page
        Sticky at top of lists


 */