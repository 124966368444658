import React, {useState} from "react";
import {Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {Link} from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import CategoryIcon from "@mui/icons-material/Category";
import MenuIcon from '@mui/icons-material/Menu';
import WrapTextIcon from '@mui/icons-material/WrapText';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Crop169Icon from '@mui/icons-material/Crop169';
import ArticleIcon from '@mui/icons-material/Article';
import {AttachEmail, AttachFile, EditNote, FormatListBulleted, People, PermMedia, SmartButton} from "@mui/icons-material";

type NavigationItems = {

    id : string,
    label : string,
    icon: JSX.Element,
    link: string,
}

type NavigationArray = {

    id : string,
    children: Array<NavigationItems>,
}

let navigationArrayScheiber : Array<NavigationItems> = [
    {id: "S01", label: "Munkatársak", icon: <People />, link: "/workers"},
    {id: "S02", label: "Bérlapok feltöltése", icon: <AttachFile />, link: "/scheiber-payroll"},
    {id: "S03", label: "Bérlapok kezelése", icon: <PermMedia />, link: "/manage-payroll"},
    {id: "S04", label: "Bérlapok kiküldés", icon: <AttachEmail />, link: "/payroll-mail-form"},
    {id: "S05", label: "Aktív gombok", icon: <SmartButton />, link: "/scheiber-payroll"},
    {id: "S06", label: "Fogadóest", icon: <EditNote />, link: "/scheiber-payroll"},
]

let navigationArrayFile : Array<NavigationItems> = [
    {id: "B01", label: "File containers", icon: <FormatListBulleted/>, link: "/file-manager-containers"},
    {id: "B02", label: "Browse files", icon: <CloudDoneIcon/>, link: "/file-manager"},
    {id: "B03", label: "Upload", icon: <CloudUploadIcon/>, link: "/file-manager-upload"},
]

let navigationArrayArticle : Array<NavigationItems> = [
    {id: "P01", label: "Articles", icon: <ArticleIcon/>, link: "/article-list"},
    {id: "P02", label: "Categories", icon: <CategoryIcon/>, link: "/article-category"},
]

let navigationArrayMenu : Array<NavigationItems> = [
    {id: "C01", label: "Menu list", icon: <MenuIcon/>, link: "/menu-list"},
    {id: "C02", label: "Menu editor", icon: <WrapTextIcon/>, link: "/menu-editor"},
    {id: "C03", label: "Button editor", icon: <Crop169Icon/>, link: "/menu-button-editor"},
]

function createNavigationCategories() : Array<NavigationArray> {

    const result: Array<NavigationArray> = [];

    result.push({
        id: '',
        children: [
            {id: "000", label:  "Home", icon: <HomeIcon/>, link: "/"},
        ],
    })
    // TODO fixme
    result.push({
        id: 'Scheiber Iskola',
        children: navigationArrayScheiber,
    })
    result.push({
        id: 'File manager',
        children: navigationArrayFile,
    })
    result.push({
        id: 'Articles',
        children: navigationArrayArticle
    })

    result.push({
        id: 'Appbar menu',
        children: navigationArrayMenu
    })

    return result
}

const navigationCategories = createNavigationCategories()

const item = {
    py: '2px',
    px: 3,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover, &:focus': {
        bgcolor: 'rgba(255, 255, 255, 0.08)',
    },
};

const itemCategory = {
    boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
    py: 1.5,
    px: 3,
};

const link = {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    marginLeft: 20,
    "&:hover": {
        color: "yellow",
        borderBottom: "1px solid white",
    },
};

export function NavigationMenu() {

    const [selectionId, setSelectionId] = useState("")

    return <List disablePadding>
        <ListItem sx={{...item, ...itemCategory, fontSize: 22, color: '#fff'}}>
            GecseVa'r framework
        </ListItem>
        {navigationCategories.map(({id, children}) => (
            <Box key={id} sx={{bgcolor: '#101F33'}}>
                <ListItem sx={{py: 2, px: 3}}>
                    <ListItemText sx={{color: '#fff'}}>{id}</ListItemText>
                </ListItem>
                {children.map(({id: childId, label, icon, link}) => (
                    <Link key={childId} to={link} style={{ textDecoration: 'none' }}>
                        <ListItem  disablePadding key={childId}>
                            <ListItemButton selected={selectionId === childId} sx={item}
                                            onClick={() => setSelectionId(childId)}>
                                <ListItemIcon>{icon}</ListItemIcon>
                                <ListItemText>{label}</ListItemText>
                            </ListItemButton>
                        </ListItem>
                    </Link>
                ))}
                <Divider sx={{mt: 2}}/>
            </Box>
        ))}
    </List>
}