import {baseUrlAdmin, HttpService} from "./HttpService";
import ArticleCategoryModel from "../models/ArticleCategoryModel";
import {AxiosResponse} from "axios";

class ArticleCategoryService extends HttpService {

    data: ArticleCategoryModel

    constructor() {
        super()
        this.data = new ArticleCategoryModel()
    }
    get(callback : (status: number, resp: AxiosResponse) => void) {
        this.httpClient.get(baseUrlAdmin + "article-category", {
            params: {

            },
        })
            .then((resp: AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch((error: any) => {
                // handle error
                this.handleError(error, (success) => {
                    if (success) {
                        this.get(callback)
                    }
                })
            })
    }
    insert(data: string, callback : (status: number, resp: AxiosResponse) => void) {
        this.httpClient.post(baseUrlAdmin + "article-category", data, {
            params: {

            },
        })
            .then((resp: AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch((error: any) => {
                // handle error
                this.handleError(error, (success) => {
                    if (success) {
                        this.insert(data, callback)
                    }
                })
            })
    }
    update(data: string, callback : (status: number, resp: AxiosResponse) => void) {
        this.httpClient.put(baseUrlAdmin + "article-category", data, {
            params: {

            },
        })
            .then((resp: AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch((error: any) => {
                // handle error
                this.handleError(error, (success) => {
                    if (success) {
                        this.update(data, callback)
                    }
                })
            })
    }

}

const articleCategoryService = new ArticleCategoryService();
export default articleCategoryService;