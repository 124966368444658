export class HeaderMenu {
    header  = new Array<HeaderButton>()
    menu = new Array<NavigationItem>()
}

export type HeaderButton = {
    title : string
    route : string
    element : string
    valid_from : Date
    valid_to: Date | null
}

export class NavigationItem {

    id : string = ""
    label : string = ""
    level: string = ""
    articleId?: number
    articlePath?: string = ""
    articleTitle: string = ""
    articleCategoryId?: number
    articleCategoryName: string = ""
    submenu?: NavigationItem
}

/*

Menu link 	Enabled 	Operations


Menu link title
The text to be used for this link in the menu.
Link

    The location this menu link points to.
    Start typing the title of a piece of content to select it. You can also enter an internal path such as /node/add or an external URL such as http://example.com. Enter <front> to link to the front page. Enter <nolink> to display link text only. Enter <button> to display keyboard-accessible link text only.

Enabled
A flag for whether the link should be enabled in menus or hidden.
Description
Shown when hovering over the menu link.
Show as expanded
If selected and this menu link has children, the menu will always appear expanded. This option may be overridden for the entire menu tree when placing a menu block.
Parent link
The maximum depth for a link and all its children is fixed. Some menu links may not be available as parents if selecting them would exceed this limit.
Weight
Link weight among links in the same menu at the same depth. In the menu, the links with high weight will sink and links with a low weight will be positioned nearer the top.


 */