import {useEffect, useState} from "react";

export function EmailSenderForm() {

    const [emails, setEmails] = useState([])

    useEffect(() => {

    }, []);

    return <>
    </>
}