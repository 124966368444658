import {baseUrlAdmin, HttpService} from "./HttpService";
import {AxiosResponse} from "axios";

class MenuService extends HttpService {

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor() {
        super()
    }

    get(callback : (status: number, resp: AxiosResponse) => void): Object {
        this.httpClient.get(baseUrlAdmin + "menu", {
            params: {

            },
        })
            .then((resp: AxiosResponse) => {
                //console.log(resp.data)
                callback(resp.status, resp)
            })
            .catch((error: any) => {
                // handle error
                this.handleError(error, (success) => {
                    if (success) {
                        this.get(callback)
                    }
                })
            })

        return ""
    }
    update(content : string, callback : (status: number, resp: AxiosResponse) => void): Object {
        this.httpClient.put(baseUrlAdmin + "menu", content,{
            params: {

            },
        })
            .then((resp: AxiosResponse) => {
                //console.log(resp.data)
                callback(resp.status, resp)
            })
            .catch((error: any) => {
                // handle error
                this.handleError(error, (success) => {
                    if (success) {
                        this.update(content, callback)
                    }
                })
            })

        return ""
    }
}

const menuService = new MenuService()
export default menuService